import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { itemTypes } from 'src/utils/utilities'
import { ItemCommentary } from './Row/ItemCommentary'
import { TaxFree } from '../Items/TaxFree/TaxFree'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { useDispatch } from 'react-redux'
import { setGeneralConfig, setItemTable } from 'src/actions/transaction.action'
import NumberField from '../NumberField/NumberField'
import { renderLineQuantities } from 'src/utils/utilitiesV2'

interface Props {
  currentItem: IItemSelectTable // Item principal modificado actual en la tabla
  index?: number // Indice del item en la tabla
  select?: React.ReactNode // Componente para seleccionar el item
  disabled?: boolean // Indica si el item esta deshabilitado
  type: number // Tipo de transacion
  updateValues: (type: number) => void // Funcion para actualizar los valores de la tabla, type 0: actualizacion global, 1: actualizar solo item
  maxDiscountCompany: number // Descuento maximo de la empresa (Configuracion)
  updateTax: (item: IItemSelectTable) => void // Funcion para actualizar el item exento de impuestos
  useLocations: boolean // Indica si se usa ubicaciones
  mobile?: boolean // Indica si es una vista movil
  fieldEditPrice?: boolean // Indica si se puede editar el precio
}

/**
 * Componente para mostrar el árbol de ítems de un item principal
 * @component
 * @param {IItemSelectTable} currentItem Item principal modificado actual en la tabla
 * @param {number} index Indice del item en la tabla
 * @param {React.ReactNode } select Componente para seleccionar el item
 * @param {boolean} disabled Indica si el item esta deshabilitado
 * @param {number} type Tipo de transacion
 * @param {void} updateValues  Funcion para actualizar los valores de la tabla, type 0: actualizacion global, 1: actualizar solo item
 * @param {number} maxDiscountCompany Descuento maximo de la empresa (Configuracion)
 * @param {void} updateTax Funcion para actualizar el item exento de impuestos
 * @param {boolean} useLocations Indica si se usa ubicaciones
 * @param {boolean} mobile Indica si es una vista movil
 * @param {boolean} fieldEditPrice Indica si se puede editar el precio
 * @returns Componente
 */
const LineQuantitiesComponent = ({
  currentItem,
  index,
  select,
  disabled,
  type,
  updateValues,
  updateTax,
  maxDiscountCompany,
  useLocations,
  mobile,
  fieldEditPrice,
}: Props) => {
  const { line } = currentItem

  const dispatch = useDispatch()

  const [commentaryShow, setCommentaryShow] = useState(false)
  const [variationShow, setVariationShow] = useState(false)

  const transactionOuts =
    type === itemTypes.SELL || type === itemTypes.TRANSFER || type === itemTypes.WASTE
  const transactionsIn = type === itemTypes.PURCHASE || type === itemTypes.EXPENSE

  const useDecimals = currentItem.decimals !== null

  useEffect(() => {
    if (line !== undefined && line.length > 0) {
      if (type !== itemTypes.PRODUCTION)
        currentItem.line = renderQuantities(currentItem.baseProductQuantity, line)
      renderValues()
      updateValues(0)
    }
  }, [currentItem.baseProductQuantity, currentItem.selectedPrice, currentItem.render]) // Actualizando valores del árbol de ítems.

  useEffect(() => {
    if (type !== itemTypes.PURCHASE && type !== itemTypes.EXPENSE) renderDiscounts()
  }, [currentItem.discount])

  useEffect(() => {
    if (
      (type === itemTypes.PURCHASE || type === itemTypes.EXPENSE) &&
      Object.entries(currentItem).length !== 0 &&
      !fieldEditPrice
    ) {
      getSinglePriceBySubTotal(currentItem.subTotal)
    }
  }, [currentItem.subTotal])

  /**
   * Renderiza los valores del árbol de ítems
   */
  const renderValues = () => {
    if (
      currentItem.line &&
      type !== itemTypes.PURCHASE &&
      type !== itemTypes.EXPENSE &&
      type !== itemTypes.RECIPE_ITEMS
    ) {
      const total = getLineTotal()
      currentItem.subTotal = total - (currentItem.discount || 0)
      currentItem.subTotalWithoutDiscount = total
      renderDiscounts()
    }
  }

  /**
   * Actualiza el descuento maximo de toda el árbol de ítems
   * @returns None
   */
  const renderDiscounts = () => {
    currentItem.maxDiscount = 0
    currentItem.line &&
      currentItem.line.length > 0 &&
      currentItem.line.forEach(l => {
        l.discount = getNewDiscount(l, currentItem.discount)
        if (l.quantity > 0) {
          const nowQuantity = (l.presentationFactor || 1) * l.quantity
          const maxDiscount = getMaxDiscount(l, nowQuantity / (l.presentationFactor || 1))
          currentItem.maxDiscount =
            currentItem.maxDiscount + parseFloat(maxDiscount.toString())
        }
      })
  }
  /**
   * Calcula el nuevo descuento cuando se actualiza la cantidad o descuento general.
   * @param {ILineItem} item Item del árbol de ítems
   * @param {number} discount Descuento del item principal
   * @returns number
   */
  const getNewDiscount = (item: ILineItem, discount: number): number => {
    const total = currentItem.subTotalWithoutDiscount //getTotalWithOutDiscount()
    let newDiscount
    if (item.productId === currentItem.value) {
      newDiscount =
        (item.quantity *
          (currentItem.selectedPrice
            ? currentItem.selectedPrice.value
            : currentItem.price) *
          discount) /
        total
    } else {
      newDiscount =
        (item.quantity *
          (item.priceInSale ? item.priceInSale : item.productData.price) *
          discount) /
        total
    }
    return Number(newDiscount !== 0 ? newDiscount.toFixed(2) : newDiscount)
  }

  /**
   * Actualiza los precios/costos de cada item del árbol de ítems
   * @param subTotal Subtotal
   * @returns none
   */
  const getSinglePriceBySubTotal = (subTotal: number) => {
    currentItem.haveQuantities = false
    if (currentItem.individualTotal) {
      let total = 0
      line &&
        line !== null &&
        line.forEach(l => {
          if (l.quantity !== null) {
            if (l.quantity === 0) {
              l.ownPrice = 0
              l.subTotal = 0
            } else {
              currentItem.haveQuantities = true
              const price = l.subTotal / (l.quantity !== 0 ? l.quantity : 1) || 0
              l.ownPrice = Number(price.toFixed(2))
              total += price
            }
          }
        })
      currentItem.subTotal = total
    } else {
      let totalQuantityBase = 0
      line &&
        line !== null &&
        line.forEach(l => {
          if (l.quantity !== null) {
            if (l.quantity === 0) {
              l.ownPrice = 0
              if (!currentItem.haveQuantities) currentItem.subTotal = 0
              if (type === itemTypes.PURCHASE) {
                // TODO: Valida importation
                currentItem.tax = 0
                currentItem.customPrice = 0
              }
            } else {
              currentItem.haveQuantities = true
              totalQuantityBase +=
                l.presentationFactor !== null
                  ? l.presentationFactor * l.quantity
                  : l.quantity
            }
          }
        })

      const priceBase = subTotal / (totalQuantityBase !== 0 ? totalQuantityBase : 1) || 0
      line &&
        line !== null &&
        line.forEach(l => {
          if (l.quantity !== null) {
            if (l.quantity === 0) {
              l.ownPrice = 0
              if (!currentItem.haveQuantities) currentItem.subTotal = 0
              if (type === itemTypes.PURCHASE) {
                currentItem.tax = 0
                currentItem.customPrice = 0
              }
            } else {
              currentItem.haveQuantities = true
              const subtotal =
                priceBase * (l.presentationFactor !== null ? l.presentationFactor : 1) ||
                0
              l.ownPrice = Number(Number(subtotal || 0).toFixed(2))
            }
          }
        })
    }
  }

  /**Lista los items del árbol de ítems
   * @param {IItemSelectTable} item
   * @param {number} key
   */
  const getSubItem = (item: IItemSelectTable, key: number) => {
    return (
      <div>
        <ul className={'base-sub-items'}>
          <li key={key}>
            <div>{item.code + '-' + item.name}</div>
          </li>
        </ul>
      </div>
    )
  }

  /**
   * Obtiene el calculo del precio de un item del árbol de ítems, en base al precio principal
   * @param item Item del árbol de ítems
   * @returns number
   */
  const getSubItemPrice = (item: ILineItem): number => {
    const { saleRecipe, price, selectedPrice } = currentItem
    const { productData, presentationFactor } = item

    const productPresentation = line.find(l => (l.productId = currentItem.id))

    let itemPrice = 0 // precio del subitem
    if (productData) {
      const useFactor = productData.useFactor
      if (saleRecipe == null) {
        itemPrice = useFactor
          ? (presentationFactor || 1) *
            ((selectedPrice ? selectedPrice.value : price) /
              (productPresentation !== null ? productPresentation.presentationFactor : 1))
          : productData.price
      } else {
        itemPrice = useFactor
          ? (item.subProductValue / currentItem.subProductValue) *
            (selectedPrice ? selectedPrice.value : price)
          : productData.price
      }
    }
    item.priceInSale = Number((itemPrice || 0).toFixed(2))
    return Number((itemPrice || 0).toFixed(2))
  }

  /**
   * Calcula el subtotal de un item del árbol de ítems
   * @param item Item en el árbol de ítems
   * @param subItem Si es un subItem o principal
   * @returns number
   */
  const getSubTotal = (item: ILineItem, subItem: boolean): number => {
    let subTotal = 0
    const pricePrincipal = currentItem.selectedPrice
      ? currentItem.selectedPrice.value
      : currentItem.price
    if (subItem) {
      subTotal += (item.quantity || 0) * getSubItemPrice(item)
    } else {
      if (item.quantity >= 0) subTotal += (item.quantity || 0) * pricePrincipal
    }
    return subTotal //- (item.discount || 0)
  }

  /**
   * Obtiene el subtotal mediante el árbol de ítems.
   * @param without Sin descuento
   * @returns number
   */
  const getLineTotal = (): number => {
    let subtotal = 0
    currentItem.haveQuantities = false
    line.forEach(lineItem => {
      if (lineItem.quantity > 0) {
        currentItem.haveQuantities = true
        subtotal += getSubTotal(
          lineItem,
          lineItem.productData.id === currentItem.value ? false : true,
        )
      }
    })
    return subtotal
  }

  /**
   *  Obtiene el descuento maximo de un item.
   * @param item    Item del árbol de ítems
   * @param quantity  Cantidad del item
   * @returns number
   */
  const getMaxDiscount = (item: ILineItem, quantity: number): number => {
    let max = 0
    if (currentItem.saleRecipe) {
      max = getSubTotal(item, false)
    } else {
      if (isNaN(quantity)) quantity = 0
      if (currentItem.discountLimit) {
        return currentItem.discountLimitedNumber * parseFloat(quantity.toString())
      } else {
        const discountIndividual =
          item.priceInSale ||
          (currentItem.selectedPrice
            ? currentItem.selectedPrice.value
            : currentItem.price)
        max =
          parseFloat((discountIndividual || 0).toString()) *
          parseFloat(quantity.toString())
      }
    }
    return Number(
      Number.parseFloat((((maxDiscountCompany || 0) * max) / 100).toString()).toFixed(2),
    )
  }

  const updateSubTotal = (): number => {
    let sub = 0
    currentItem.haveQuantities = false
    currentItem.line.forEach(x => {
      if (x.quantity > 0 && x.ownPrice) {
        currentItem.haveQuantities = true
        sub = sub + x.quantity * (x.ownPrice || 0)
      }
    })
    return Number(sub.toFixed(2))
  }

  /**
   * Metodo que permite cambiar la cantidad de un item y actualizar los valores
   * @param item Item del árbol de ítems a cambiar cantidad y actualizar valores
   * @param quantity Nueva cantidad
   * @returns none
   */
  const changeQuantity = (item: ILineItem, quantity: number) => {
    if (isNaN(quantity)) quantity = 0

    quantity = Number(quantity)
    if (quantity < 0) quantity = 0
    if (
      type === itemTypes.PURCHASE ||
      type === itemTypes.EXPENSE ||
      type === itemTypes.RECIPE_ITEMS
    ) {
      // currentItem.active = false //series
      item.quantity = quantity
      if (!fieldEditPrice) {
        getSinglePriceBySubTotal(currentItem.subTotal)
      } else currentItem.subTotal = updateSubTotal()
      updateValues(1)
    } else {
      if (currentItem === null) return

      item.quantity = quantity

      const nowQuantity = (item.presentationFactor || 1) * quantity

      const maxDiscountAux: number = getMaxDiscount(
        item,
        nowQuantity / (item.presentationFactor || 1),
      )
      currentItem.maxDiscount = maxDiscountAux
      if (parseFloat((currentItem.discount || 0).toString()) > maxDiscountAux)
        currentItem.discount = maxDiscountAux

      currentItem.error = null

      const subTotal = getLineTotal()
      currentItem.subTotalWithoutDiscount = subTotal

      currentItem.subTotal = subTotal - (currentItem.discount || 0)
      currentItem.line = renderQuantities(currentItem.baseProductQuantity, line)

      updateValues(1)
    }
  }

  /**
   * Obtiene la existencia real de un item en base a la cantidad de existencia del base.
   * @param quantity Cantidad de existencia del ítem base
   * @returns number
   */
  const getRealQuantities = (quantity: number): number => {
    let current = quantity
    for (const p of line || []) {
      if (p.quantity !== null && p.quantity !== 0)
        current -= parseFloat((p.quantity * (p.presentationFactor || 1)).toFixed(2))
    }
    return current
  }

  /**
   * Realiza y actualiza las cantidades disponibles de los items del árbol de ítems
   * @param quantity Cantidad total de existencia del ítem base
   * @param line  Árbol de ítems
   * @returns none
   */
  const renderQuantities = (quantity: number, line: ILineItem[]): ILineItem[] => {
    if (quantity < 0) currentItem.alert = true
    else currentItem.alert = false

    return renderLineQuantities(getRealQuantities(quantity), useDecimals, line, false)
  }

  /**
   * Retorna el listado de subitems del árbol de ítems.
   * @param item Item del árbol de ítems
   * @param index Index del item
   * @returns JSX.Element
   */
  const renderItemValues = (item: ILineItem, index: number) => {
    return (
      <div key={index}>
        <Row style={{ marginTop: '13px' }}>
          <>
            <Col xs={8} md={8}>
              {getSubItem(item.productData, index)} {/* Only getting the name */}
            </Col>
          </>
        </Row>
      </div>
    )
  }

  const getCommentaryComponent = () => {
    return (
      <ItemCommentary
        item={currentItem}
        onHide={() => {
          currentItem.commentaryShow = false
          setCommentaryShow(false)
          updateValues(1)
        }}
        show={commentaryShow || currentItem.commentaryShow}
        updateValues={() => updateValues(1)}
      />
    )
  }

  const renderVariations = () => {
    return (
      <div className="ml-2">
        {/* @ts-expect-error Error por tipado JS*/}
        <ListVariations
          show={variationShow || currentItem.showVariations}
          onSave={(list: ICategorization[]) => {
            currentItem.showVariations = false
            setVariationShow(false)
            currentItem.listVariations = list
            if (
              (type === itemTypes.SELL ||
                type === itemTypes.TRANSFER ||
                type === itemTypes.WASTE) &&
              useLocations
            ) {
              let response = ''
              list.forEach(x => {
                response = response + x.id
              })
              dispatch(
                setGeneralConfig({
                  showList: true,
                  value: currentItem.value,
                  getVariationsValue: true,
                  params: {
                    limit: true,
                    type: type,
                    categories: response,
                  },
                }),
              )
              dispatch(
                setItemTable({
                  index: index,
                }),
              )
            } else {
              const listV = []
              list.forEach(item => {
                listV.push(item.id)
              })
              dispatch(
                setGeneralConfig({
                  showList: false,
                  value: currentItem.value,
                  getItemValue: true,
                  params: { categories: listV },
                }),
              )
              dispatch(
                setItemTable({
                  index: index,
                }),
              )
            }
          }}
          border
          onHide={() => {
            currentItem.showVariations = false
            setVariationShow(false)
          }}
          modeSelection
          data={currentItem.listCategorization || []}
          selected={currentItem.listVariations && currentItem.listVariations}
          onClick={() => {
            setVariationShow(true)
          }}
        />
      </div>
    )
  }

  return (
    <>
      {mobile ? (
        line?.map(item => (
          <>
            <NumberField
              value={item.quantity || 0}
              onValueChange={value => {
                changeQuantity(item, value)
              }}
              decimals={currentItem.decimals !== null ? currentItem.decimals : 0}
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <div>
                    {item.productId !== currentItem.id
                      ? item.productData.name
                      : 'Cantidad'}
                  </div>
                  <div>{item.currentQuantity + ' u.'}</div>
                </div>
              }
              labelStyle={{ width: '100%' }}
            />
            {transactionsIn && !fieldEditPrice && (
              <div
                className="left ml-2"
                style={{
                  fontWeight: 400,
                  marginBottom: 10,
                }}>
                Precio unitario: {item.ownPrice || '0.00'}
              </div>
            )}
          </>
        ))
      ) : (
        <>
          <td>
            <div>{select}</div>
            <div style={{ marginTop: '25px' }}>
              {line &&
                line
                  .filter(l => l.productData.id !== currentItem.value)
                  .map((item, index) => {
                    return renderItemValues(item, index)
                  })}
            </div>
            <div>
              {currentItem.id !== 1 &&
                currentItem.listCategorization !== null &&
                currentItem.id !== undefined &&
                renderVariations()}
            </div>
            <div>
              {(type === itemTypes.SELL ||
                type == itemTypes.TRANSFER ||
                type === itemTypes.WASTE) &&
                currentItem.id !== undefined &&
                getCommentaryComponent()}
            </div>
            <div>
              {type === itemTypes.SELL &&
                currentItem.id !== undefined &&
                currentItem.id !== 1 && (
                  <>
                    <TaxFree
                      item={currentItem}
                      toSelect={currentItem}
                      updateTax={updateTax}
                    />
                  </>
                )}
            </div>
          </td>
          {useLocations && transactionOuts && (
            <td>
              {currentItem.listLocations &&
                currentItem.listLocations.map(item => (
                  <>
                    {item.name}
                    <br />
                  </>
                ))}
            </td>
          )}
          <td>
            {line &&
              line.map((item, i) => (
                <Col
                  style={{
                    paddingLeft: 0,
                    marginTop: item.productData.id !== currentItem.value ? 13 : 0,
                  }}
                  key={i}>
                  <Row>
                    <Col
                      xs={transactionOuts ? 8 : 12}
                      md={transactionOuts ? 8 : 12}
                      xl={transactionOuts ? 8 : 12}>
                      <NumberField
                        value={item.quantity || 0}
                        onValueChange={value => {
                          changeQuantity(item, value)
                        }}
                        disabled={disabled}
                        decimals={useDecimals ? currentItem.decimals : 0}
                      />
                    </Col>
                    {transactionOuts && (
                      <Col xs={4} md={4} xl={4} style={{ position: 'relative' }}>
                        <p
                          style={{
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                          }}>
                          {currentItem.id !== 1
                            ? `${item.currentQuantity || 0.0} u.`
                            : ''}
                        </p>
                      </Col>
                    )}
                  </Row>
                </Col>
              ))}
          </td>
        </>
      )}
    </>
  )
}

export const LineQuantities = React.memo(LineQuantitiesComponent)
