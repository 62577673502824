import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { geolocated } from 'react-geolocated'
import { Modal, Row, Col } from 'react-bootstrap'
import {
  faCheck,
  faCheckDouble,
  faExclamationTriangle,
  faComment,
  faWarehouse,
  faWindowClose,
  faTicketAlt,
  faTextWidth,
  faListOl,
  faPrint,
} from '@fortawesome/free-solid-svg-icons'
import 'sweetalert/dist/sweetalert.css'

import {
  Select,
  FormText,
  Icon,
  TableV2,
  Button,
  CustomDate,
  Switch,
  CustomCreate,
  Card,
  Paragraph,
} from 'src/components'

import Gallery, { imageTypes } from 'src/components/gallery/Gallery'

import {
  actionTypes as orderActions,
  getProductExistenceByOrder,
  deliverOrder,
  onPrintInvoice,
} from 'src/actions/orders.actions'

import {
  selectGetInvoiceByOrder,
  selectProductExistence,
} from 'src/selectors/orders.selector'

import { isAllowed, selectUsers } from 'src/selectors/modules.selector'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'

import { selectCurrentModule } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  hasErrorsSelector,
  singleErrorSelector,
} from 'src/selectors/error.selector'

import {
  selectCompanyIsLimit,
  selectCompanyFieldById,
  selectCompanyCountry,
} from 'src/selectors/company.selector'

import { getItemValue } from 'src/utils/formatters'
import {
  getOrderInvoiceConcepts,
  getTipsAvailable,
  onSetPrintData,
} from 'src/actions/restaurant.actions'
import { getCompanyField } from 'src/actions/company.actions'
import { valEmail, validateGeoLocation } from 'src/utils/utilities'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { AlertKolo } from 'src/components/Alert/AlertKolo'
import { hideAlert, showAlert } from 'src/actions/alert.actions'
import InvoiceConceptField from 'src/components/InvoiceConceptField/InvoiceConceptField'
import { orderPermissions, quotePermissions } from 'src/enums/permissions'
import { Country } from 'src/enums/countries'
import { PaymentType } from 'src/enums/paymentTypes'
import { orderDispatchType } from 'src/enums/orderDispatchStatus'
import { invoiceTicketByInstructions } from 'src/components/Printer/TicketEpson'
import { actionTypes as actionPDF } from 'src/actions/resolutions.actions'
import { setInvoicePrintInstructions } from 'src/actions/invoice.actions'
import HTMLBuilder from 'src/components/HtmlBuilder/HTMLBuilder'
import useDisabledDays from 'src/hooks/useDisabledDays'

const payments = [
  { value: 1, label: 'Efectivo' },
  { value: 2, label: 'Crédito' },
  { value: 6, label: 'Tarjeta' },
]

const ModalOrderConfirm = ({
  show,
  ignoreGPS,
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
  onHideSuccess,
  ...props
}) => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)
  const module = useSelector(selectCurrentModule)
  const users = useSelector(selectUsers)
  const existence = useSelector(selectProductExistence)
  const orderClientEmail = useSelector(state => selectCompanyFieldById(state, 116))
  const automaticFel = useSelector(state => selectCompanyFieldById(state, 117))
  const invoices = useSelector(selectGetInvoiceByOrder)

  const invoiceType = useSelector(state => selectCompanyFieldById(state, 90))
  const certificate = useSelector(state => selectCompanyFieldById(state, 94))
  const isLimit = useSelector(selectCompanyIsLimit)

  const loadingConfirm = useSelector(state =>
    loadingSelector([orderActions.CONFIRM_SINGLE_ORDER])(state),
  )
  const hasErrorConfirm = useSelector(state =>
    hasErrorsSelector([orderActions.CONFIRM_SINGLE_ORDER])(state),
  )
  const errorConfirm = useSelector(state =>
    singleErrorSelector([orderActions.CONFIRM_SINGLE_ORDER])(state),
  )

  const loadingExistences = useSelector(state =>
    loadingSelector([orderActions.GET_EXISTENCE])(state),
  )

  const loadingPDF = useSelector(state =>
    loadingSelector([actionPDF.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER])(state),
  )

  /*permissions*/
  const canConfirmByAnotherUser = useSelector(state =>
    isAllowed(state, [1116, 2816, 11666]),
  )
  const canAddRetention = useSelector(state => isAllowed(state, [2815, 1115, 11665]))

  const canIgnoreCoords = useSelector(state => isAllowed(state, [2810, 1159, 11673]))
  const canIgnoreQuoteCoords = useSelector(state => isAllowed(state, [6508, 6458]))
  const canIgnoreFinalConsumerClient = useSelector(state =>
    isAllowed(state, [2818, 1118, 11668]),
  )

  const canAddCash = useSelector(state => isAllowed(state, [2823, 1124]))
  const canDontCertificate = useSelector(state => isAllowed(state, [1123, 2923]))
  const canDontCertificateQuote = useSelector(state => isAllowed(state, [6507, 6457]))
  const canChangeOrderDate = useSelector(state => isAllowed(state, [1127, 2826]))
  const canUseOrderConcepts = useSelector(state =>
    isAllowed(state, [orderPermissions.manageConcepts]),
  )
  const canUseQuoteConcepts = useSelector(state =>
    isAllowed(state, [quotePermissions.manageConcepts]),
  )

  const [actions, setAction] = useState({ confirm: false })
  const [order, setOrder] = useState({
    certificate: automaticFel.value === 'true' || automaticFel.value === '1',
    paymentDate: null,
  })

  const isQuote = order?.quote

  const canConfirmWithTotalExistence = useSelector(state =>
    isAllowed(state, [
      isQuote
        ? quotePermissions.confirmWithFulLInventory
        : orderPermissions.confirmWithFullDispatch,
    ]),
  )
  const canConfirmWithParcialExistence = useSelector(state =>
    isAllowed(state, [
      isQuote
        ? quotePermissions.confirmWithPartialInventory
        : orderPermissions.confirmWithPartialDispatch,
    ]),
  )
  const canConfirmWithPendingExistence = useSelector(state =>
    isAllowed(state, [
      isQuote
        ? quotePermissions.confirmWithPendingInventory
        : orderPermissions.confirmWithPendingDispatch,
    ]),
  )
  const canConfirmWithoutExistence = useSelector(state =>
    isAllowed(state, [
      isQuote
        ? quotePermissions.confirmWithoutInventory
        : orderPermissions.confirmWithoutInventory,
    ]),
  )

  const [loading, setLoading] = useState(false)
  const [alertKolo, setAlertKolo] = useState({ title: '' })
  const [orderImages, setOrderImages] = useState([])
  const [tipIdValue, setTipIdValue] = useState({})
  const [email, setEmail] = useState({ value: null, label: 'Sin seleccionar' })
  const [errorEmail, setErrorEmail] = useState('')
  const [modalOptions, setModalOptions] = useState({})
  const [concept, setConcept] = useState({})

  const disabledConfirmDays = useDisabledDays(order.certificate)

  const canUseConcepts = order.quote ? canUseQuoteConcepts : canUseOrderConcepts

  useEffect(() => {
    if (!show) return

    setEmail({ value: null, label: 'Sin seleccionar' })
    dispatch(getTipsAvailable())
    dispatch(getOrderInvoiceConcepts())
    dispatch(getCompanyField(94))
    dispatch(getCompanyField(116))
    dispatch(getCompanyField(117))

    const email = props.order?.client ? props.order.client.email : null
    const paymentType =
      props.order.quote && props.order.balance < props.order.total
        ? 2
        : props.order.paymentType

    setOrder({
      ...props.order,
      certificate: automaticFel.value === 'true' || automaticFel.value === '1',
      email,
      paymentType,
    })
    if (canConfirmByAnotherUser && users.length <= 0)
      dispatch(getUsersChildrenByModule(module, true))
    if (props.order.warehouse)
      dispatch(
        getProductExistenceByOrder(
          props.order.warehouse.id,
          [props.order.orderId],
          props.order.status === 8,
        ),
      )

    const tipValue = props.order?.tipValue > 0 ? props.order?.tipValue : 0

    setTipIdValue({ id: null, value: tipValue })
  }, [show])

  useEffect(() => {
    if (
      !order.email ||
      orderClientEmail.value === 'false' ||
      orderClientEmail.value === '0'
    )
      return
    setEmail({ value: order.email, label: order.email })
  }, [orderClientEmail.value])

  useEffect(() => {
    const newOrder = {
      ...order,
      certificate: automaticFel.value === 'true' || automaticFel.value === '1',
    }
    setOrder(newOrder)
  }, [automaticFel.value])

  useEffect(() => {
    if (loadingConfirm && props.order.show) setAction({ confirm: true })
    else if (actions.confirm) {
      setAction({ confirm: false })
      if (hasErrorConfirm)
        dispatch(
          showAlert({
            ...handlerError(errorConfirm.message),
          }),
        )
      else {
        let printInstructions = []
        if (invoices?.length > 0) printInstructions = invoices[0].printInstructions || []
        dispatch(setInvoicePrintInstructions(printInstructions))

        setAlertKolo({
          title: 'Orden confirmada satisfactoriamente',
          show: true,
          type: 'success',
          onHide: () => {
            onHide(true)
          },
          custom: (
            <Row className={'container-buttons'}>
              <Button
                style={{ marginTop: 0 }}
                right
                color={'secondary'}
                onH
                onClick={() => {
                  onHide(false)
                }}>
                Cerrar Detalle
              </Button>
              <Button
                style={{ marginTop: 0 }}
                right
                color={'primary'}
                loading={loadingPDF}
                onClick={() => {
                  onHide(true)
                }}>
                Ver Facturas
              </Button>
              <Button
                color={'accent'}
                disabled={loadingPDF}
                loading={loadingPDF}
                onClick={async () =>
                  dispatch(
                    onPrintInvoice({
                      active: true,
                      orderId: order.orderId,
                      print: true,
                      documentType: 1,
                    }),
                  )
                }>
                <Icon spin={loadingPDF} icon={faPrint} hab tooltip={'Imprimir factura'} />
              </Button>
              <Button
                color={'accent'}
                onClick={() => {
                  dispatch(
                    onSetPrintData({
                      show: true,
                      element: 'modal-confirm-order-ticket',
                      customData: invoiceTicketByInstructions(printInstructions),
                    }),
                  )
                }}>
                <Icon icon={faTicketAlt} hab tooltip={'Imprimir ticket'} />
              </Button>
            </Row>
          ),
        })
      }
    }
  }, [loadingConfirm])

  const withExistence = item => {
    if (item.product?.noInventory) return true
    const p = existence.find(e => e.id === item.id && e.product === item.product.id)
    if (!p) return false
    return Number(p.existence) >= Number(p.quantity)
  }

  const withTotalExistence = item => {
    const p = existence.find(e => e.product === item.product.id && e.id === item.id)
    if (!p) return false
    return p.totalWithReserve >= p.quantity
  }

  // const confirm = async (removeInventory, partial, pendingDispatch, finishDate) => {
  const confirm = async (dispatchType, finishDate) => {
    setModalOptions({ ...modalOptions, showDate: false, show: false })

    setLoading(true)
    const isFinalConsumer =
      order.client && order.client.cotizapClient === 3000 && canIgnoreFinalConsumerClient

    if (
      ((!canIgnoreCoords && !order.quote) || (!canIgnoreQuoteCoords && order.quote)) &&
      !isFinalConsumer &&
      !ignoreGPS
    ) {
      const { latitude, longitude } = order.client

      const gpsResponse = await validateGeoLocation(
        { isGeolocationAvailable, isGeolocationEnabled, coords },
        order.warehouse.id,
        { latitude, longitude },
        'El usuario no se encuentra en el radio del cliente. Distancia actual: ',
      )

      setLoading(false)

      if (!gpsResponse.ok)
        return dispatch(
          showAlert({
            ...handlerError(gpsResponse.message),
          }),
        )
    }

    dispatch(
      deliverOrder(
        order.orderId,
        module,
        {
          user: order.confirmBy ? order.confirmBy.value : null,
          paymentDate: order.paymentDate ? order.paymentDate.valueOf() : null,
          commentary: order.commentary,
          retention: order.retention,
          gratification: tipIdValue.value,
          cash: order.cash,
          email: email?.value,
          number: order.number,
          dispatchDate: order.dispatchDate ? order.dispatchDate.valueOf() : null,
          dontCertificate:
            (order.felDocumentType === 2 && country.id !== Country.HN) ||
            !order.certificate,
          confirmDate: order.confirmDate ? order.confirmDate.valueOf() : null,
          receipt: order.receipt,
          paymentType: order.paymentType,
          description: order.description,
          finishDate: finishDate ? finishDate.valueOf() : null,
          conceptId: concept?.value,
          products: [],
          dispatchType,
        },
        orderImages.map(item => ({ url: item.original, name: item.name })),
      ),
    )
    setLoading(false)
  }

  const onHide = invoice => {
    hideAlert()
    setAlertKolo({ ...alertKolo, show: false })
    onHideSuccess(invoice)
    setOrder({})
    setTipIdValue({ id: null, value: null })
    setConcept({})
  }

  const getOptionsEmail = () => {
    const emails = [{ value: null, label: 'Sin seleccionar' }]
    if (order?.email) emails.push({ value: order.email, label: order.email })
    return emails
  }

  const getHeaders = () => {
    let val1 = ['product', 'code']
    let val2 = ['product', 'name']

    return [
      {
        label: 'Existencia',
        show: true,
        className: 'mini',
        value: ['custom'],
        custom: item => {
          const hasExistence = withExistence(item)
          const hasTotalExistence = withTotalExistence(item)
          return (
            <Icon
              tooltip={
                loadingExistences
                  ? 'Cargando existencias...'
                  : hasExistence
                  ? 'Con existencia'
                  : hasTotalExistence
                  ? 'Con existencia reservada'
                  : 'Sin existencia'
              }
              spin={loadingExistences}
              icon={
                hasExistence
                  ? faCheck
                  : hasTotalExistence
                  ? faExclamationTriangle
                  : faWindowClose
              }
              color={
                loadingExistences
                  ? '#226095'
                  : hasExistence
                  ? 'green'
                  : hasTotalExistence
                  ? '#b9b975'
                  : 'red'
              }
            />
          )
        },
      },
      {
        label: 'SKU / Código',
        show: true,
        type: 'text',
        value: val1,
        className: 'mini',
        custom: item =>
          item.productData
            ? item.productData.code
            : item.product
            ? item.product.code
            : 'Desconocido',
      },
      {
        label: 'Producto',
        show: true,
        type: 'text',
        value: val2,
        className: 'mini',
        custom: item =>
          item.productData ? (
            <>
              {item.productData.name}
              {item.categories && <ListVariations selected={item.categories} />}
            </>
          ) : item.product ? (
            <>
              {item.product.name}
              {item.categories && <ListVariations selected={item.categories} />}
            </>
          ) : (
            'Desconocido'
          ),
      },
      {
        label: 'Cantidad',
        show: true,
        value: ['quantity', 'name'],
        type: 'text',
        className: 'mini',
      },
      { config: true, show: true, label: '', className: 'mini' },
    ]
  }

  const getDateCredit = () => {
    if (order.paymentType === 2) {
      return order?.paymentDate
    } else if (order.paymentType === 7) {
      const paymentDateForMultiple = order?.multiplePayments?.find(
        payment => payment?.paymentType === 2,
      )?.paymentDate
      if (paymentDateForMultiple) {
        return new Date(paymentDateForMultiple)
      } else {
        return null
      }
    } else {
      return null
    }
  }
  const getItemsMobile = data => {
    const array = []
    let store = localStorage.getItem(`orderConf-`)
    store = store
      ? JSON.parse(store)
      : {
          title: { value: ['quantity'], type: 'text' },
          subtitle: { value: ['quantity', 'name'], type: 'text' },
          complement1: { value: ['quantity', 'name'], type: 'text' },
          complement2: { value: ['quantity'], type: 'text' },
        }
    data.forEach(item => {
      const we = withExistence(item)
      const wte = withTotalExistence(item)
      const more = [
        {
          title: `Existencia: `,
          info: (
            <Icon
              tooltip={
                we
                  ? 'Con existencia'
                  : wte
                  ? 'Con existencia reservada'
                  : 'Sin existencia'
              }
              icon={we ? faCheck : wte ? faExclamationTriangle : faWindowClose}
              color={we ? 'green' : wte ? '#b9b975' : 'red'}
            />
          ),
        },
        {
          title: `SKU / Código: `,
          info: item.productData
            ? item.productData.code
            : item.product
            ? item.product.code
            : 'Desconocido',
        },
        {
          title: `Producto: `,
          info: item.productData
            ? item.productData.name
            : item.product
            ? item.product.name
            : 'Desconocido',
        },
        { title: `Cantidad: `, info: item.quantity },
      ]

      const object = {
        id: item.id,
        title: getItemValue(item, store.title.value, store.title.type),
        subtitle: getItemValue(item, store.subtitle.value, store.subtitle.type),
        complement1: getItemValue(item, store.complement1.value, store.complement1.type),
        complement2: getItemValue(item, store.complement2.value, store.complement2.type),
        key: `orderConf-${item.id}`,
        action: '',
        more,
      }
      array.push(object)
    })
    return array
  }

  const confirmButtons = modal => {
    const disabled =
      (order?.quote && order.paymentType === PaymentType.MULTIPLE) ||
      (order.paymentType === PaymentType.CREDIT && !order.paymentDate) ||
      (order.paymentType === PaymentType.MULTIPLE &&
        order.multiplePayments.some(
          payment => payment.paymentType === PaymentType.CREDIT,
        ) &&
        !order.paymentDate) ||
      (invoiceType && invoiceType.value === '2' ? orderImages.length <= 0 : false)

    const loadingConfirmButtons = loading || loadingConfirm

    let buttons = [
      {
        show: canConfirmWithTotalExistence,
        label: 'Confirmar con despacho de inventario',
        disabled,
        loading: loadingConfirmButtons,
        icon: faCheckDouble,
        onClick: () => confirm(orderDispatchType.confirmWithFullDispatch),
      },
      {
        show: canConfirmWithoutExistence && !order.linked,
        label: 'Confirmar sin inventario',
        disabled,
        loading: loadingConfirmButtons,
        icon: faCheck,
        description:
          'Ignora la existencia de todos los items indicados en la orden de venta.',
        onClick: () => confirm(orderDispatchType.confirmWithoutInventory),
      },
      {
        show:
          (order.type === 1 || order.type === 5) &&
          !order.linked &&
          canConfirmWithPendingExistence,
        label: 'Confirmar con despacho pendiente',
        disabled,
        loading: loadingConfirmButtons,
        icon: faCheck,
        description:
          'Generará un despacho pendiente con la existencia de todos los items indicados en la orden de venta.',
        onClick: () => {
          setModalOptions({
            ...modalOptions,
            showDate: true,
            onConfirm: date =>
              confirm(orderDispatchType.confirmWithPendingDispatch, date),
          })
        },
      },
      {
        show:
          (order.type === 1 || order.type === 5) &&
          !order.linked &&
          canConfirmWithParcialExistence,
        label: 'Confirmar con despacho parcial',
        disabled,
        loading: loadingConfirmButtons,
        icon: faCheck,
        description:
          'Realizará el despacho de las existencias disponibles de los ítems indicados en la orden, las cantidades faltantes generarán un despacho pendiente.',
        onClick: () => {
          setModalOptions({
            ...modalOptions,
            showDate: true,
            finishDate: null,
            onConfirm: date =>
              confirm(orderDispatchType.confirmWithPartialDispatch, date),
          })
        },
      },
    ]

    if (buttons.filter(b => b.show).length > 2 && !modal)
      buttons = [
        {
          show:
            !modal &&
            (canConfirmWithTotalExistence ||
              canConfirmWithoutExistence ||
              canConfirmWithParcialExistence ||
              canConfirmWithPendingExistence),
          label: 'Otras opciones de confirmación',
          disabled,
          loading: loadingConfirmButtons,
          icon: faListOl,
          color: 'secondary',
          onClick: () => setModalOptions({ show: true }),
        },
        ...buttons,
      ]

    const b = buttons.filter(b => b.show)

    return !modal && b.length > 2 ? [b[0], b[1]] : b
  }

  const showModalConfirm = show && !modalOptions.show && !modalOptions.showDate

  return (
    <div>
      <Modal
        show={showModalConfirm}
        centered
        size={'xl'}
        onHide={() => {
          onHide()
          setOrder({})
          setTipIdValue({ id: null, value: null })
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Orden: #{order.number}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className={'custom-modal-body'}>
            {canConfirmByAnotherUser && (
              <Col xl={6} md={6} sm={12}>
                <Select
                  disabled={loading || loadingConfirm}
                  label={'Confirmar bajo el siguiente usuario'}
                  options={[{ value: null, label: 'sin seleccionar' }, ...users]}
                  value={order.confirmBy}
                  onChange={confirmBy => setOrder({ ...order, confirmBy })}
                />
              </Col>
            )}

            <Col xl={6} md={6} sm={12}>
              <FormText
                disabled={loading || loadingConfirm}
                prepend={<Icon icon={faTicketAlt} tooltip={'Código'} />}
                label={'Código de venta (Opcional)'}
                value={order.number}
                onChange={({ target }) => {
                  setOrder({ ...order, number: target.value })
                }}
              />
            </Col>
            {order.quote && (
              <Col xl={12}>
                <div className={'column'}>
                  <label className={'ftf-form-label left mt-3'}>
                    * Agregar tipo de pago
                  </label>
                  <Row className={'container-buttons d-flex'}>
                    {payments.map(p => (
                      <Button
                        key={p.value}
                        color={order.paymentType === p.value ? 'primary' : 'accent'}
                        style={{ flex: 1 }}
                        onClick={() => setOrder({ ...order, paymentType: p.value })}
                        disabled={
                          (order.quote && order.balance < order.total) ||
                          (order.quote &&
                            order.billPayments &&
                            p.value !== PaymentType.CREDIT)
                        }>
                        {p.label}
                      </Button>
                    ))}
                  </Row>
                </div>
              </Col>
            )}

            {/*{order && order.paymentType === 1 && (
              <Col xl={6} md={6} sm={12}>
                <FormText
                  disabled={loading || loadingConfirm}
                  prepend={<Icon icon={faReceipt} tooltip={'Recibo'} />}
                  label={'Agregar número de recibo (Opcional)'}
                  value={order.receipt}
                  onChange={({ target }) => {
                    setOrder({ ...order, receipt: target.value })
                  }}
                />
              </Col>
            )}*/}

            {canUseConcepts && (
              <Col xl={6} md={6} sm={12}>
                <InvoiceConceptField
                  isQuote={order.quote}
                  value={concept}
                  onChange={newConcept => {
                    if (!newConcept) return
                    setConcept(newConcept)
                  }}
                />
              </Col>
            )}

            <Col xl={12} md={12} sm={12}>
              <FormText
                disabled={loading || loadingConfirm}
                prepend={<Icon icon={faComment} tooltip={'Comentario'} />}
                label={'Comentario del cliente'}
                placeholder={'Agrega un comentario'}
                value={order.commentary}
                onChange={({ target }) => {
                  setOrder({ ...order, commentary: target.value })
                }}
              />
            </Col>

            <Col xl={12} md={12} sm={12}>
              <FormText
                disabled={loading || loadingConfirm}
                prepend={<Icon icon={faTextWidth} tooltip={'Descripción'} />}
                label={'Descripción de la orden'}
                value={order.description}
                onChange={({ target }) => {
                  setOrder({ ...order, description: target.value })
                }}
              />
            </Col>

            <Col xl={6} md={6} sm={12}>
              <CustomCreate
                label={'Correo donde llegará la factura (Opcional)'}
                options={getOptionsEmail()}
                value={email}
                disabled={loading || loadingConfirm}
                textLabel={'Nuevo correo:'}
                onChange={value => {
                  if (!value) return
                  setEmail(value)
                }}
                onCreateOption={value => {
                  if (valEmail(value)) {
                    setEmail({ value, label: value })
                    setErrorEmail('')
                  } else {
                    setErrorEmail('Formato incorrecto')
                  }
                }}
                error={errorEmail}
              />
            </Col>

            {country.id === Country.GT &&
              !isLimit &&
              !order.linked &&
              canAddRetention &&
              !order.quote && (
                <Col xl={6} md={6} sm={12}>
                  <FormText
                    disabled={loading || loadingConfirm}
                    prependMoneySymbol
                    label={'Agregar retención'}
                    placeholder={'Agregar retención'}
                    value={order.retention}
                    type={'number'}
                    onChange={({ target }) => {
                      const { value } = target
                      let retention = value || 0

                      if (retention < 0) retention = 0

                      retention = Number.parseFloat(retention)

                      if (value <= order.total) setOrder({ ...order, retention })
                    }}
                  />
                </Col>
              )}

            {!isLimit &&
              !order.linked &&
              canAddCash &&
              order.paymentType === 1 &&
              !order.quote && (
                <Col xl={12} md={12} sm={12}>
                  <FormText
                    disabled={loading || loadingConfirm}
                    info={
                      'Al hacer un abono parcial con efectivo, este se sumará al efectivo circulante del responsable de la venta y el saldo restante se sumará a cuentas por cobrar en la App: Cobros con Tarjeta.'
                    }
                    prependMoneySymbol
                    label={'Abonar parcial con efectivo'}
                    placeholder={'Agregar efectivo'}
                    value={order.cash}
                    type={'number'}
                    onChange={({ target }) => {
                      let { value } = target
                      if (!value || value < 0) value = 0
                      const cash = Number.parseFloat(value)
                      setOrder({ ...order, cash })
                    }}
                  />
                </Col>
              )}
            {(order.paymentType === PaymentType.CREDIT ||
              (order.paymentType === PaymentType.MULTIPLE &&
                order.multiplePayments.some(
                  payment => payment.paymentType === PaymentType.CREDIT,
                ))) && (
              <Col xl={6} md={6} sm={12}>
                <CustomDate
                  disabled={loading || loadingConfirm}
                  label={'Programar fecha de pago para'}
                  value={getDateCredit()}
                  disabledDays={{ before: new Date() }}
                  onDayChange={e => {
                    setOrder({ ...order, paymentDate: e })
                  }}
                  required
                />
              </Col>
            )}

            {!(order.quote && order.billPayments) && (
              <Col xl={12} className={'mt-2'}>
                {order.felDocumentType === 2 && country.id !== Country.HN ? (
                  <div>
                    <i>
                      <b>No emisión de factura electrónica:</b>
                      &nbsp; El tipo de documento Factura con items sin IVA no se puede
                      certificar.
                    </i>
                  </div>
                ) : certificate &&
                  certificate.value === '1' &&
                  ((canDontCertificate && !order.quote) ||
                    (canDontCertificateQuote && order.quote)) ? (
                  <div>
                    <Switch
                      topLabel
                      label={'Generar factura electrónica'}
                      info={
                        'Si la opción no está activa no se generará un documento legal, únicamente un documento de empresa'
                      }
                      checked={order.certificate}
                      onChange={({ target }) => {
                        setOrder({ ...order, certificate: target.checked })
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    {order.certificate ? (
                      <i>
                        <b>Siempre emitir factura electrónica:</b> Al confirmar la orden
                        de venta, se emitirá una factura electrónica.
                      </i>
                    ) : (
                      <i>
                        <b>No emisión de factura electrónica:</b>
                        Al confirmar la orden de venta, no se emitirá una factura
                        electrónica.
                      </i>
                    )}
                  </div>
                )}
              </Col>
            )}

            {canChangeOrderDate && (
              <Col xl={6} md={6} sm={12}>
                <CustomDate
                  disabled={loading || loadingConfirm}
                  label={'Confirmar bajo la siguiente fecha'}
                  value={order.confirmDate}
                  disabledDays={disabledConfirmDays}
                  onDayChange={e => {
                    setOrder({ ...order, confirmDate: e })
                  }}
                />
              </Col>
            )}

            <Col xl={12} md={12} sm={12}>
              <FormText
                prepend={<Icon icon={faWarehouse} label={'Bodega'} />}
                label={'Bodega saliente'}
                value={order.warehouse ? order.warehouse.name : '...'}
                disabled
                onChange={() => undefined}
              />
            </Col>

            {invoiceType && invoiceType.value === '2' && (
              <Col xl={12} md={12} sm={12}>
                <Gallery
                  imageType={imageTypes.INVOICES}
                  images={orderImages}
                  onUploadSuccess={(url, name) =>
                    setOrderImages([
                      ...orderImages,
                      { original: url, thumbnail: url, name },
                    ])
                  }
                  onDelete={(image, index) => {
                    orderImages.splice(index, 1)
                    setOrderImages(orderImages)
                  }}
                  maxWidth={450}
                />
              </Col>
            )}

            {props.order.presale ? (
              <Col xl={12} md={12} sm={12}>
                <Card
                  style={{
                    fontSize: 15,
                    textAlign: 'center',
                    color: '#a21e8c',
                    marginTop: 5,
                  }}>
                  Al confirmar como pre-venta, la orden de venta será confirmada y
                  facturada pero no se despachará el inventario hasta la fecha indicada a
                  continuación.
                </Card>
                <CustomDate
                  disabled={loading || loadingConfirm}
                  label={'Programar fecha para despachar el inventario'}
                  value={order.dispatchDate}
                  disabledDays={{ before: new Date() }}
                  onDayChange={e => {
                    setOrder({ ...order, dispatchDate: e })
                  }}
                  required
                />
              </Col>
            ) : (
              <Col xl={12} md={12} sm={12}>
                <TableV2
                  items={order.details ? order.details : []}
                  headers={getHeaders()}
                  storageKey={`orderConf-`}
                  itemsMobile={getItemsMobile(order.details ? order.details : [])}
                  footerText={
                    order.details?.every(withExistence) ? '' : 'Sin existencia suficiente'
                  }
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer className={'container-buttons'}>
          {confirmButtons(false).map((b, i) => (
            <Button
              key={i}
              icon={b.icon}
              loading={b.loading}
              disabled={b.disabled}
              color={b.color}
              onClick={() => b.onClick()}>
              {b.label}
            </Button>
          ))}
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalOptions.show && !modalOptions.showDate}
        centered
        size={'md'}
        onHide={() => setModalOptions({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Opciones de confirmación</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row style={{ padding: '15px 0', gap: '15px 0' }}>
            {confirmButtons(true).map((b, i) => (
              <Col
                key={i}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                <div>
                  <h2>{b.label}</h2>
                  <Paragraph>{b.description}</Paragraph>
                </div>

                <Button
                  disabled={b.disabled}
                  style={{ width: '100%', margin: '10px auto' }}
                  loading={b.loading}
                  icon={b.icon}
                  onClick={() => {
                    b.onClick()
                  }}>
                  {b.label}
                </Button>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalOptions.showDate}
        centered
        size={'sm'}
        onHide={() => setModalOptions({ ...modalOptions, showDate: false })}>
        <Modal.Header>
          <Modal.Title>Fecha de despacho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={'12'}>
              <CustomDate
                label={'Fecha de despacho (Opcional)'}
                value={modalOptions.finishDate}
                onDayChange={finishDate =>
                  setModalOptions({ ...modalOptions, finishDate })
                }
                disabledDays={[]}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={!modalOptions.finishDate}
              onClick={() => modalOptions.onConfirm(modalOptions.finishDate)}>
              Continuar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <AlertKolo {...alertKolo} />

      <HTMLBuilder id={'modal-confirm-order-ticket'} />
    </div>
  )
}

export default geolocated({
  positionOptions: { enableHighAccuracy: true, userDecisionTimeout: 5000 },
})(ModalOrderConfirm)
