import { CookieEnum, CookieExpirationHours } from 'src/enums/cookies'

/**
 * Set cookie by name and value with expiration time in hours from CookieExpirationHours
 * @param name
 * @param value
 */
function setCookie(name: CookieEnum, value: string) {
  const hoursToExpire = CookieExpirationHours[name]
  const expirationTime = new Date(
    Date.now() + hoursToExpire * 60 * 60 * 1000,
  ).toUTCString()
  document.cookie = `${name}=${value}; expires=${expirationTime}; path=/; domain=.kolo-app.com; Secure; SameSite=Strict`
}

export { setCookie }
