import './TableOrderPage.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Container, Row, Spinner } from 'react-bootstrap'
import {
  Button,
  FormText,
  Money,
  NumberField,
  CustomCreate,
  Paragraph,
} from 'src/components'
import IconButton from 'src/components/buttons/IconButton'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { actionTypes as types, getRecipe } from 'src/actions/restaurant.actions'
import {
  selectCategorySelected,
  selectListTags,
  selectPosConfigurationByType,
  selectPOSSearch,
  selectTableCategoryItems,
  selectTableItem,
  selectTableItems,
  selectTableSearchItems,
} from 'src/selectors/restaurant.selector'

import { actionTypes as productActionTypes } from 'src/actions/products.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { posPanelConfigurations } from 'src/enums/posPanelConfigurations'
import { objectFullCopy } from 'src/utils/utilities'
import {
  getRoundedInteger,
  findLargerValue,
  calculateProportionalValue,
} from 'src/utils/utilitiesV2'

const defaultTab = {
  itemQuantity: 1,
  commentary: '',
  productLineQuantities: [],
  customRecipe: {},
  discount: 0,
}

const TableOrderItemModal = ({ onChange, onClose, noViewRecipeInModalItem = false }) => {
  const dispatch = useDispatch()

  const loadingRecipe = useSelector(state => loadingSelector([types.GET_RECIPE])(state))
  const recipe = useSelector(state => state.restaurant.recipe)
  const search = useSelector(selectPOSSearch)
  const selectedCategory = useSelector(selectCategorySelected)
  const tags = useSelector(selectListTags)

  const showItem = useSelector(selectTableItem)
  const {
    show,
    name,
    code,
    recipeId,
    price,
    quantity,
    offerPrice,
    offerQuantity,
    id,
    automatic,
    requiresAmountOnly,
    existence,
    seeExistence,
    combo,
    parentId,
    openVariation,
  } = showItem

  const showItemPrice = !openVariation && !parentId

  const productLine = useSelector(state => state.products.productLine)
  const items = useSelector(selectTableItems)
  const categoryItems = useSelector(selectTableCategoryItems)
  const searchItems = useSelector(selectTableSearchItems)

  const canUseComments = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.comments),
  )
  const canUseDiscounts = useSelector(state =>
    selectPosConfigurationByType(state, posPanelConfigurations.discounts),
  )

  const isSearchActive = !!search
  const isCategorySelected = Object.keys(selectedCategory).length !== 0

  const usedItems = isSearchActive
    ? searchItems
    : isCategorySelected
    ? categoryItems
    : items

  const loadingProductLine = useSelector(state =>
    loadingSelector([productActionTypes.SEE_PRODUCT_LINE])(state),
  )

  const [actions, setActions] = useState({ get: false })

  const [customPrice, setCustomPrice] = useState(0)
  const [tabs, setTabs] = useState([defaultTab])
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const selectedTab = tabs[selectedTabIndex]

  const itemPrice = requiresAmountOnly ? customPrice : price

  const setSelectedTab = tab => {
    const newTabs = [...tabs]
    newTabs[selectedTabIndex] = tab
    setTabs(newTabs)
  }

  useEffect(() => {
    if (automatic) {
      onSave()
    }
  }, [automatic])

  useEffect(() => {
    setSelectedTab({
      ...selectedTab,
      productLineQuantities: productLine
        .filter(product => usedItems.find(item => item.id === product.id))
        .map(product => ({
          ...product,
          ...(items.find(item => item.id === product.id) || {}),
          quantity: product.id === id ? 1 : 0,
        })),
    })
  }, [productLine])

  useEffect(() => {
    if (!show) {
      setSelectedTabIndex(0)
      setTabs([defaultTab])
      return
    }

    let newQuantity = 1
    if (quantity && quantity > 0) newQuantity = quantity
    setSelectedTab({ ...selectedTab, commentary: '', itemQuantity: newQuantity })

    setCustomPrice(0)

    if (recipeId && !requiresAmountOnly) dispatch(getRecipe(recipeId))
  }, [show])

  const getCustomRecipe = () => {
    const genericRecipe = objectFullCopy(recipe)
    const variable = false

    const newOptions = genericRecipe.options
      .filter(op => op.items && op.items.length > 0)
      .map(op => ({
        ...op,
        variable,
        items: op.items.map(i => {
          return { ...i, original: op.id === 0 ? i.quantity : null, quantity: 0 }
        }),
      }))

    return { ...genericRecipe, options: newOptions, variable }
  }

  useEffect(() => {
    if (loadingRecipe) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      if (recipe && recipe.name !== undefined) {
        setSelectedTab({
          ...selectedTab,
          customRecipe: getCustomRecipe(),
        })
      }
    }
  }, [loadingRecipe])

  const onChangeComplementQuantity = (newQuantity, optionIndex, itemIndex) => {
    const customRecipe = selectedTab.customRecipe
    customRecipe.options.filter(filterOptions)[optionIndex].items[itemIndex].quantity =
      newQuantity
    setSelectedTab({ ...selectedTab, customRecipe })
  }

  const onSave = () => {
    const request = tabs.map((tab, i) => {
      if (tab.productLineQuantities.length > 0) {
        return tab.productLineQuantities
          .filter(item => item.quantity > 0)
          .map((subItem, j) => ({
            ...subItem,
            groupValue: new Date().valueOf() + i + j,
            subtotal: subItem.quantity * subItem.price,
            iPrice: subItem.price,
            supplies: [],
            suppliesText: '',
            commentary: tab.commentary,
            productId: subItem.productId || subItem.id,
            discount: calculateProportionalValue(
              getTotal(selectedTab),
              subItem.quantity * subItem.price,
              tab.discount,
            ),
          }))
      }

      const detail = {
        ...showItem,
        variable: tab.customRecipe.variable,
        groupValue: new Date().valueOf() + i,
        quantity: tab.itemQuantity,
        discount: tab.discount,
        subtotal: getTotal(tab),
        iPrice: getSubtotal(tab) / tab.itemQuantity,
        price: itemPrice,
        extra: getTotal(tab) - getSubtotal(tab),
        supplies: [],
        suppliesText: '',
        commentary: tab.commentary,
        productId: showItem.productId || showItem.id,
        tag: tab.tag?.value,
      }

      tab.customRecipe.options?.forEach(option => {
        const supplies =
          option.id === 0 ? option.items : option.items.filter(i => i.quantity !== 0)

        let price = 0
        if (option.variablePrice) {
          const amount = getSubtotalExtra(option)
          price = amount / supplies.reduce((acc, i) => acc + i.quantity, 0)
        }

        supplies.forEach(optionItem => {
          detail.supplies.push({
            id: optionItem.id,
            quantity: optionItem.quantity,
            price: price > 0 ? price : optionItem.price,
            original: optionItem.original,
            name: optionItem.name,
            variable: !optionItem.original || optionItem.original === 0,
            quantityToInventory: optionItem.quantityToInventory,
          })
        })
      })
      detail.supplies
        .filter(s => s.quantity !== 0)
        .forEach((s, i) => {
          const sign = s.quantity < 0 ? '' : '+'
          const separate = i + 1 === detail.supplies.length ? '' : ', '
          const quantity = !s.variable ? s.quantity * s.original : s.quantity

          detail.suppliesText += `${sign}${quantity} ${s.name}${separate}`
        })

      return detail
    })
    onChange(request.flat())
  }

  const getSubtotal = tab => {
    let response = 0
    let variable = false

    if (offerPrice && offerPrice > 0) {
      const v = Math.trunc(tab.itemQuantity / offerQuantity)
      if (v > 0) {
        const quantityWithOffer = v * offerQuantity
        const quantityWithoutOffer = tab.itemQuantity - quantityWithOffer

        response += quantityWithOffer * offerPrice
        response += quantityWithoutOffer * itemPrice
        variable = true
      }
    }
    if (!variable) return tab.itemQuantity * itemPrice

    return response
  }

  const getSubtotalExtra = op => {
    let itemsToCalculate
    const { items, variablePrice, min } = op
    if (variablePrice) {
      const sorted = items.filter(i => i.quantity > 0).sort((a, b) => b.price - a.price)

      itemsToCalculate = []
      let count = min

      sorted.forEach(i => {
        if (count > 0) {
          if (i.quantity <= count) {
            itemsToCalculate.push({ quantity: i.quantity, price: i.price })
            count -= i.quantity
          } else {
            itemsToCalculate.push({ quantity: count, price: i.price })
            count = 0
          }
        }
      })
    } else itemsToCalculate = items
    return itemsToCalculate.reduce((total, item) => total + item.quantity * item.price, 0)
  }

  const getTotal = tab => {
    if (!tab.productLineQuantities.length) {
      let subtotal = getSubtotal(tab)

      tab.customRecipe?.options?.forEach(op => {
        subtotal += tab.itemQuantity * getSubtotalExtra(op)
      })

      return subtotal
    }
    let subtotal = 0
    tab.productLineQuantities.forEach(p => {
      subtotal += p.quantity * p.price || 0
    })
    return subtotal
  }

  const disabledRecipeOption = (
    { required, multiple, variablePrice, min, max, items, variable, unlimited },
    valMin = false,
  ) => {
    let response
    const quantityUsed = items.reduce((total, item) => total + (item.quantity || 0), 0)
    if (variablePrice) min = max
    if (unlimited) {
      min = 0
      max = 1000000
    }
    if (multiple || variable) {
      min = (!variable && min) || 1
      max = (!variable && max) || 1

      response =
        (!valMin && quantityUsed < max) || quantityUsed < min || quantityUsed > max
    } else {
      response = quantityUsed < 1
      if (!response && !required) return false
    }

    if (response && valMin) return required

    return response
  }

  const isSaveDisabled = tabs.some(
    tab =>
      tab.itemQuantity <= 0 ||
      tab.customRecipe.options?.some(op => op.id !== 0 && disabledRecipeOption(op, true)),
  )

  const filterOptions = obj => {
    return noViewRecipeInModalItem ? obj.id !== 0 : true
  }

  return (
    <div>
      <Modal
        show={show}
        centered
        size={'lg'}
        onHide={onClose}
        data-cy="modal-custom-recipe">
        <Modal.Header closeButton>
          <Modal.Title>
            {code} {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className={'pos-item-modal-body'}>
            {selectedTab.customRecipe?.options?.length > 0 && (
              <div className={'pos-tabs-flex'}>
                <div className={'pos-tabs-base'}>
                  <div className={'pos-tabs item-modal'}>
                    {tabs.map((tab, i) => (
                      <div
                        key={i}
                        className={`pos-tab ${selectedTabIndex === i ? 'selected' : ''}`}
                        onClick={() => setSelectedTabIndex(i)}>
                        <span className="no-select">Detalle {i + 1}</span>
                        {tabs.length > 1 && (
                          <span
                            className="button-remove"
                            onClick={e => {
                              e.stopPropagation()
                              const newTabs = tabs.filter((t, index) => index !== i)
                              setTabs(newTabs)
                              if (selectedTabIndex === tabs.length - 1)
                                setSelectedTabIndex(newTabs.length - 1)
                            }}>
                            ✕
                          </span>
                        )}
                      </div>
                    ))}
                    <div
                      className={'pos-tab add'}
                      onClick={() => {
                        const newTabs = [
                          ...tabs,
                          {
                            ...defaultTab,
                            customRecipe: getCustomRecipe(),
                          },
                        ]
                        setTabs(newTabs)
                        setSelectedTabIndex(newTabs.length - 1)
                      }}>
                      <span className="no-select">+</span>
                    </div>
                  </div>
                  <div className="pos-tabs-decorator" />
                </div>
                <IconButton
                  className={'info-hover'}
                  color={'rgba(34, 96, 149, 0.75)'}
                  tooltip={'Añade un detalle y configura sus receta de forma individual.'}
                  icon={faInfoCircle}
                  size={'lg'}
                />
              </div>
            )}

            {showItemPrice && (
              <div className={'b-item'}>
                {!requiresAmountOnly ? (
                  <div className={'column'}>
                    <div className={'space-between'}>
                      <Money className={'pos-item-modal-total b-user-name'}>
                        {itemPrice}
                      </Money>
                      <div className={'d-flex row align-items-center p-1 bu-b right'}>
                        <button
                          disabled={selectedTab.itemQuantity <= 1}
                          className={`pos-button-circle pbc-medium ${
                            selectedTab.itemQuantity <= 1
                              ? 'color-accent-disabled'
                              : 'color-accent'
                          }`}
                          onClick={() => {
                            const itemQuantity = selectedTab.itemQuantity - 1

                            setSelectedTab({
                              ...selectedTab,
                              itemQuantity,
                              productLineQuantities:
                                selectedTab.productLineQuantities?.map(p =>
                                  p.id === id ? { ...p, quantity: itemQuantity } : p,
                                ),
                            })
                          }}>
                          -
                        </button>
                        <NumberField
                          value={selectedTab.itemQuantity}
                          onValueChange={value =>
                            setSelectedTab({
                              ...selectedTab,
                              itemQuantity: value,
                              productLineQuantities:
                                selectedTab.productLineQuantities?.map(p =>
                                  p.id === id ? { ...p, quantity: value } : p,
                                ),
                            })
                          }
                          noArrows
                          style={{ width: 70, textAlign: 'center', margin: '0 10px' }}
                          min={1}
                        />
                        <button
                          className={'pos-button-circle pbc-medium color-accent'}
                          onClick={() => {
                            const itemQuantity = selectedTab.itemQuantity + 1

                            setSelectedTab({
                              ...selectedTab,
                              itemQuantity,
                              productLineQuantities:
                                selectedTab.productLineQuantities?.map(p =>
                                  p.id === id ? { ...p, quantity: itemQuantity } : p,
                                ),
                            })
                          }}>
                          +
                        </button>
                      </div>
                    </div>
                    {seeExistence && (
                      <Paragraph
                        style={{
                          color:
                            existence - selectedTab.itemQuantity > 0 ? 'green' : 'red',
                        }}>
                        Existencia disponible: {existence - selectedTab.itemQuantity}
                      </Paragraph>
                    )}
                  </div>
                ) : (
                  <NumberField
                    label={'Monto'}
                    value={customPrice}
                    onValueChange={setCustomPrice}
                    min={0}
                    decimals={6}
                  />
                )}

                <div className={'column'}>
                  {selectedTab.customRecipe.description && (
                    <div className={'b-user-email'}>
                      {selectedTab.customRecipe.description}
                    </div>
                  )}
                  {offerPrice && offerPrice > 0 && (
                    <Money className={'b-user-email'}>
                      Por cada <b>{offerQuantity}</b> se aplicara un precio de oferta de{' '}
                      <b>{offerPrice}</b>
                    </Money>
                  )}
                </div>
              </div>
            )}

            {!parentId && (
              <CustomCreate
                label={'Asignar ticket'}
                options={tags}
                placeholder={'Seleccionar ticket'}
                value={selectedTab.tag}
                textLabel={'Agregar: '}
                onChange={value => setSelectedTab({ ...selectedTab, tag: value })}
                onCreateOption={value =>
                  setSelectedTab({ ...selectedTab, tag: { value, label: value } })
                }
              />
            )}

            {canUseComments && (
              <FormText
                label={'Comentario'}
                value={selectedTab.commentary}
                changeValue={value =>
                  setSelectedTab({ ...selectedTab, commentary: value })
                }
              />
            )}

            {loadingRecipe && (
              <div className={'d-flex center'}>
                <Spinner animation={'grow'} />
                <Spinner animation={'grow'} />
                <Spinner animation={'grow'} />
              </div>
            )}

            {selectedTab.customRecipe?.options
              ?.filter(filterOptions)
              ?.map((op, opIndex) => {
                const disabledMore = op.unlimited
                  ? false
                  : combo || (op.id !== 0 && !disabledRecipeOption(op))

                const min = op.variablePrice ? op.max : op.min
                const max = op.max

                return (
                  <div key={op.id} className={'mt-2'} data-cy="custom-recipe">
                    <div>
                      <h6 className={'mb-1'}>{op.name}</h6>
                      {op.id !== 0 && (
                        <p>
                          <div>({op.required ? 'Requerido' : 'Opcional'})</div>
                          {op.unlimited ? (
                            <b>Sin limite</b>
                          ) : op.multiple ? (
                            min === 0 ? (
                              <>
                                Seleccione hasta{' '}
                                <b>{max > 1 ? `${max} unidades` : '1 unidad'}</b>
                              </>
                            ) : (
                              <>
                                Seleccione al menos{' '}
                                <b>{min > 1 ? `${min} unidades` : '1 unidad'}</b> o un
                                máximo de{' '}
                                <b>{max > 1 ? `${max} unidades` : '1 unidad'}</b>
                              </>
                            )
                          ) : (
                            'Seleccione 1 unidad'
                          )}
                        </p>
                      )}
                    </div>
                    {op.items.map((item, iIndex) => {
                      const minInput =
                        op.id === 0 ? getRoundedInteger(item.original * -1) : 0

                      const disabled =
                        combo ||
                        (op.id === 0
                          ? item.quantity <= item.original * -1
                          : item.quantity <= 0)

                      const appendName =
                        item.quantityToInventory > 1 ? `${item.quantityToInventory}u` : ''

                      return (
                        <div key={item.id} className={'b-item'}>
                          <div className={'space-between pl-1 pr-2'}>
                            <div className={'justify-content-start align-items-start'}>
                              <div className={'d-flex'}>
                                <div className={'b-user-name'}>{item.name}</div>
                                <div className={'b-user-email ml-1'}>{appendName}</div>
                              </div>
                              <Money className={'b-user-email'}>{item.price}</Money>
                              {op.id === 0 && (
                                <div className={'b-user-email'}>
                                  Ya se incluyen: {item.original}
                                </div>
                              )}
                            </div>

                            <div className={'d-flex row align-items-center bu-b right'}>
                              <button
                                disabled={disabled}
                                className={`pos-button-circle pbc-mini ${
                                  disabled ? 'color-accent-disabled' : 'color-accent'
                                }`}
                                onClick={() => {
                                  const newValue =
                                    (isNaN(item.quantity) ? 0 : item.quantity) - 1
                                  const itemQuantity = findLargerValue(newValue, minInput)

                                  onChangeComplementQuantity(
                                    itemQuantity,
                                    opIndex,
                                    iIndex,
                                  )
                                }}>
                                -
                              </button>
                              <NumberField
                                disabled={combo}
                                value={item.quantity}
                                onValueChange={value => {
                                  value = findLargerValue(value, minInput)

                                  onChangeComplementQuantity(value, opIndex, iIndex)
                                }}
                                noArrows
                                style={{
                                  width: 70,
                                  textAlign: 'center',
                                  margin: '0 10px',
                                }}
                                max={
                                  op.id === 0 || op.unlimited
                                    ? undefined
                                    : (!op.variable && max) || 1
                                }
                              />
                              <button
                                disabled={disabledMore}
                                className={'pos-button-circle pbc-mini '.concat(
                                  disabledMore ? 'color-accent-disabled' : 'color-accent',
                                )}
                                onClick={() => {
                                  let itemQuantity = item.quantity + 1
                                  if (
                                    !op.unlimited &&
                                    op.id !== 0 &&
                                    itemQuantity > ((!op.variable && max) || 1)
                                  )
                                    itemQuantity = (!op.variable && max) || 1

                                  onChangeComplementQuantity(
                                    itemQuantity,
                                    opIndex,
                                    iIndex,
                                  )
                                }}>
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className={'space-between mt-3'}>
                      <h6>Extra</h6>
                      <Money component="h6">{getSubtotalExtra(op)}</Money>
                    </div>
                  </div>
                )
              })}

            {productLine?.length > 1 && (
              <div>
                <div className={'pl-3 pr-3 pt-3'}>
                  <hr />
                </div>

                <div className={'mr-3'}>
                  <h5>Árbol de ítems</h5>
                  {selectedTab.productLineQuantities
                    ?.filter(item => item.id !== id)
                    ?.map((product, index) => (
                      <div className={'d-flex space-between b-item'} key={index}>
                        <div>
                          <h6>
                            {product.code} {product.name}
                          </h6>
                          <Money component="h6">{product.price}</Money>
                        </div>
                        <div className={'d-flex row align-items-center mr-2 bu-b right'}>
                          <button
                            disabled={product.quantity <= (product.id === id ? 1 : 0)}
                            className={'pos-button-circle pbc-mini '.concat(
                              product.quantity <= (product.id === id ? 1 : 0)
                                ? 'color-accent-disabled'
                                : 'color-accent',
                            )}
                            onClick={() => {
                              const itemQuantity = product.quantity - 1

                              setSelectedTab({
                                ...selectedTab,
                                productLineQuantities:
                                  selectedTab.productLineQuantities.map(p =>
                                    p.id === product.id
                                      ? { ...p, quantity: itemQuantity }
                                      : p,
                                  ),
                                itemQuantity:
                                  product.id === id
                                    ? itemQuantity
                                    : selectedTab.itemQuantity,
                              })
                            }}>
                            -
                          </button>
                          <NumberField
                            value={product.quantity}
                            onValueChange={value =>
                              setSelectedTab({
                                ...selectedTab,
                                productLineQuantities:
                                  selectedTab.productLineQuantities.map(p =>
                                    p.id === product.id ? { ...p, quantity: value } : p,
                                  ),
                                itemQuantity:
                                  product.id === id ? value : selectedTab.itemQuantity,
                              })
                            }
                            noArrows
                            style={{ width: 70, textAlign: 'center', margin: '0 10px' }}
                          />
                          <button
                            className={'pos-button-circle pbc-mini '.concat(
                              'color-accent',
                            )}
                            onClick={() => {
                              const itemQuantity = product.quantity + 1

                              setSelectedTab({
                                ...selectedTab,
                                productLineQuantities:
                                  selectedTab.productLineQuantities.map(p =>
                                    p.id === product.id
                                      ? { ...p, quantity: itemQuantity }
                                      : p,
                                  ),
                                itemQuantity:
                                  product.id === id
                                    ? itemQuantity
                                    : selectedTab.itemQuantity,
                              })
                            }}>
                            +
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {!requiresAmountOnly && (
              <div>
                <div className={'pt-3'}>
                  <hr />
                </div>

                {canUseDiscounts && (
                  <div className={'column'}>
                    <div className={'space-between'}>
                      <h6>Subtotal</h6>
                      <Money component="h6">{getTotal(selectedTab)}</Money>
                    </div>
                    <NumberField
                      label={'Descuento'}
                      value={selectedTab.discount}
                      decimals={2}
                      isMoney
                      min={0}
                      max={getTotal(selectedTab)}
                      onValueChange={value =>
                        setSelectedTab({ ...selectedTab, discount: value })
                      }
                    />
                  </div>
                )}

                <div className={'space-between'}>
                  <h6>Total</h6>
                  <Money component="h6">
                    {getTotal(selectedTab) - (selectedTab.discount || 0)}
                  </Money>
                </div>

                {productLine.length === 0 && (
                  <div className={'space-between'}>
                    <h6>Precio por Unidad</h6>
                    <Money component="h6">
                      {(getTotal(selectedTab) - (selectedTab.discount || 0)) /
                        selectedTab.itemQuantity || 0}
                    </Money>
                  </div>
                )}

                {loadingProductLine && (
                  <div>
                    <div className={'d-flex center'}>
                      <Spinner animation={'grow'} />
                      <Spinner animation={'grow'} />
                      <Spinner animation={'grow'} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'} data-cy={'agregar-pos'}>
            <Button disabled={isSaveDisabled} onClick={onSave}>
              Agregar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default TableOrderItemModal
