import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import {
  faArchive,
  faCheckDouble,
  faEllipsisV,
  faFileCsv,
  faFileExcel,
  faMoneyBill,
  faMoneyCheckAlt,
  faSyncAlt,
  faTag,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import {
  Icon,
  Card,
  Button,
  CustomDate,
  CustomTabs,
  SelectedDates,
  Dropdown as DropdownCustom,
  Title,
  FormText,
  TableV2,
  Select,
} from 'src/components'

import { formatDateFromMillis } from 'src/utils/formatters'

import {
  actionTypes as orderTypes,
  getPaymentOrders,
  onConfirmOrderPayment,
  deleteSingleOrder,
  getAllOrders as getOrderBalanceByCustomers,
  onCreatePaymentSpecificOrder,
  onDeferredPayment,
  onCreateOrderPayment,
  changeCategorization,
  onUpdateOrderParameters,
  onNullifyPayment,
  getPaymentReceipt,
  editMassivePayments,
  getArchivedPayment,
  editArchivedPayment,
  onNullifySpecificPaymentNumber,
  getOrdersSummary,
  getPaymentsOrder,
  getCpcReportCSV,
  onNullifyReceiptPayment,
  printOrder,
  onPrintInvoice,
} from 'src/actions/orders.actions'
import {
  selectOrdersPayment,
  selectTotalOrdersPayment,
  selectGetAllOrders,
  selectTotalOrders,
  selectPaymentReceipt,
  selectArchivedPayments,
  selectGetOrdersSummary,
} from 'src/selectors/orders.selector'

import {
  getPermission,
  isAllowed,
  selectCurrentUserRoles,
  selectUsers,
} from 'src/selectors/modules.selector'
import { selectCurrentUser } from 'src/selectors/user.selector'

import { actionTypes, getAllClients2 as getAllClients } from 'src/actions/clients.actions'
import { selectAllClients } from 'src/selectors/clients.selector'
import {
  actionTypes as resolutionsTypes,
  cancelInvoiceAndOrder,
} from 'src/actions/resolutions.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  singleErrorSelector,
  hasErrorsSelector,
  hasErrors,
} from 'src/selectors/error.selector'

import CreateOrderPayment from 'src/content/Orders/OrderPayment/CreateOrderPayment'
import IconButton from 'src/components/buttons/IconButton'
import Folder, { categoryType } from 'src/components/folders/Folder'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import {
  assignCategorizationToMultipleEntities,
  getAllCategorizations,
  getCustomCategorizationsByEntity,
} from 'src/actions/categorization.actions'
import ButtonIcon from 'src/components/buttons/IconButton'
import FAB from 'src/components/buttons/FAB'
import { onSetModalOrder, setModalOrderPayment } from 'src/actions/utilities.actions'

import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import {
  actionTypes as reportTypes,
  getSegmentationReportOptExcel,
} from 'src/actions/report.actions'

import Money from 'src/components/Money/Money'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { ModalNullifyOrder } from 'src/content/Clients/CPC/Nullification/ModalNullifyOrder'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import PaymentsCSV from './PaymentsCSV'
import OrderService from 'src/services/orders.service'
import utilitiesServices from 'src/services/utilities.services'
import { PaymentTicket } from 'src/content/Clients/CPC/Payment/PaymentTicket'
import { printTicket, toMoney } from 'src/utils/utilities'
import { segmentationReportTypeEnum } from 'src/enums/reportsEnum'
import { getOrderPaymentStatus } from 'src/enums/OrderStatusEnum'

let interval = null

const defaultValue = { value: 0, label: '- Todos -' }
const defaultNullValue = { value: null, label: '- Todos -' }

const statusList = getOrderPaymentStatus()

const Payments = props => {
  const dispatch = useDispatch()

  const allClients = useSelector(selectAllClients)

  const paymentOrders = useSelector(selectOrdersPayment)
  const totalOrders = useSelector(selectTotalOrdersPayment)

  const user = useSelector(selectCurrentUser)
  const users = useSelector(selectUsers)
  const isAdmin = useSelector(state => getPermission(state, 1001))
  const categorization = useSelector(selectAllCategorizations)
  const paymentReceipt = useSelector(selectPaymentReceipt)
  const archivedPayments = useSelector(selectArchivedPayments)
  const currentCurrency = useSelector(selectCurrentCurrency)
  const allRoles = useSelector(selectCurrentUserRoles)

  const loadingSegmentationReport = useSelector(state =>
    loadingSelector([reportTypes.GET_SEGMENTATION_REPORT_EXCEL])(state),
  )
  const errorSegmentationReport = useSelector(state =>
    hasErrors([reportTypes.GET_SEGMENTATION_REPORT_EXCEL])(state),
  )

  const loadingDeferredPayment = useSelector(state =>
    loadingSelector([orderTypes.ON_DEFERRED_PAYMENT])(state),
  )
  const hasErrorDeferredPayment = useSelector(state =>
    hasErrorsSelector([orderTypes.ON_DEFERRED_PAYMENT])(state),
  )
  const errorDeferredPayment = useSelector(state =>
    singleErrorSelector([orderTypes.ON_DEFERRED_PAYMENT])(state),
  )

  const loadingNullifyPayment = useSelector(state =>
    loadingSelector([orderTypes.ON_NULLIFY_PAYMENT])(state),
  )
  const hasErrorNullifyPayment = useSelector(state =>
    hasErrorsSelector([orderTypes.ON_NULLIFY_PAYMENT])(state),
  )
  const errorNullifyPayment = useSelector(state =>
    singleErrorSelector([orderTypes.ON_NULLIFY_PAYMENT])(state),
  )

  const loadingNullifyReceipt = useSelector(state =>
    loadingSelector([orderTypes.ON_NULLIFY_RECEIPT_PAYMENT])(state),
  )
  const hasErrorNullifyReceipt = useSelector(state =>
    hasErrorsSelector([orderTypes.ON_NULLIFY_RECEIPT_PAYMENT])(state),
  )
  const errorNullifyReceipt = useSelector(state =>
    singleErrorSelector([orderTypes.ON_NULLIFY_RECEIPT_PAYMENT])(state),
  )

  const loadingPaymentOrders = useSelector(state =>
    loadingSelector([orderTypes.GET_PAYMENT_ORDERS])(state),
  )
  const loadingSaleOrders = useSelector(state =>
    loadingSelector([orderTypes.GET_ALL_ORDERS])(state),
  )
  const loadingPDF = useSelector(state =>
    loadingSelector([resolutionsTypes.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER])(state),
  )

  const saleOrders = useSelector(selectGetAllOrders)
  const totalCxc = useSelector(selectTotalOrders)
  const cxcSummary = useSelector(selectGetOrdersSummary)

  const loadingConfirm = useSelector(state =>
    loadingSelector([orderTypes.ON_CONFIRM_PAYMENT_ORDER])(state),
  )
  const hasErrorConfirm = useSelector(state =>
    hasErrorsSelector([orderTypes.ON_CONFIRM_PAYMENT_ORDER])(state),
  )
  const errorConfirm = useSelector(state =>
    singleErrorSelector([orderTypes.ON_CONFIRM_PAYMENT_ORDER])(state),
  )

  const loadingCreate = useSelector(state =>
    loadingSelector([orderTypes.ON_CREATE_PAYMENT_ORDER])(state),
  )
  const hasErrorCreate = useSelector(state =>
    hasErrorsSelector([orderTypes.ON_CREATE_PAYMENT_ORDER])(state),
  )
  const errorCreate = useSelector(state =>
    singleErrorSelector([orderTypes.ON_CREATE_PAYMENT_ORDER])(state),
  )

  const loadingDelete = useSelector(state =>
    loadingSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  )
  const hasErrorDelete = useSelector(state =>
    hasErrorsSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  )
  const errorDelete = useSelector(state =>
    singleErrorSelector([orderTypes.DELETE_SINGLE_ORDER])(state),
  )

  const loadingUpdateParams = useSelector(state =>
    loadingSelector([orderTypes.UPDATE_PARAMS])(state),
  )
  const hasErrorUpdateParams = useSelector(state =>
    hasErrorsSelector([orderTypes.UPDATE_PARAMS])(state),
  )
  const errorUpdateParams = useSelector(state =>
    singleErrorSelector([orderTypes.UPDATE_PARAMS])(state),
  )

  const loadingMassive = useSelector(state =>
    loadingSelector([orderTypes.EDIT_MASSIVE_PAYMENT])(state),
  )
  const hasErrorMassive = useSelector(state =>
    hasErrorsSelector([orderTypes.EDIT_MASSIVE_PAYMENT])(state),
  )
  const errorMassive = useSelector(state =>
    singleErrorSelector([orderTypes.EDIT_MASSIVE_PAYMENT])(state),
  )

  const loadingEditArchive = useSelector(state =>
    loadingSelector([orderTypes.EDIT_ARCHIVED_PAYMENT])(state),
  )
  const hasErrorEditArchive = useSelector(state =>
    hasErrorsSelector([orderTypes.EDIT_ARCHIVED_PAYMENT])(state),
  )
  const errorEditArchive = useSelector(state =>
    singleErrorSelector([orderTypes.EDIT_ARCHIVED_PAYMENT])(state),
  )

  const loadingClients = useSelector(state =>
    loadingSelector([actionTypes.GET_ALL_CLIENTS])(state),
  )
  const loadingArchivedPayments = useSelector(state =>
    loadingSelector([orderTypes.GET_ARCHIVED_PAYMENT])(state),
  )

  const loadingExcel = useSelector(state =>
    loadingSelector([orderTypes.GET_ORDER_BALANCE_EXCEL])(state),
  )
  const hasErrorExcel = useSelector(state =>
    hasErrorsSelector([orderTypes.GET_ORDER_BALANCE_EXCEL])(state),
  )

  const loadingPaymentReceipt = useSelector(state =>
    loadingSelector([orderTypes.GET_PAYMENT_RECEIPT])(state),
  )

  const loadingNullifyNumber = useSelector(state =>
    loadingSelector([orderTypes.ON_NULLIFY_SPECIFIC_PAYMENT_NUMBER])(state),
  )
  const hasErrorNullifyNumber = useSelector(state =>
    hasErrors([orderTypes.ON_NULLIFY_SPECIFIC_PAYMENT_NUMBER])(state),
  )

  const loadingNullifyOrder = useSelector(state =>
    loadingSelector([resolutionsTypes.CANCEL_BILL])(state),
  )
  const hasErrorNullifyOrder = useSelector(state =>
    hasErrorsSelector([resolutionsTypes.CANCEL_BILL])(state),
  )
  const errorNullifyOrder = useSelector(state =>
    singleErrorSelector([resolutionsTypes.CANCEL_BILL])(state),
  )

  const loadingReportCSV = useSelector(state =>
    loadingSelector([orderTypes.GET_PAYMENT_REPORT_CSV])(state),
  )
  const hasErrorReportCSV = useSelector(state =>
    hasErrorsSelector([resolutionsTypes.CANCEL_BILL])(state),
  )
  const errorReportCSV = useSelector(state =>
    singleErrorSelector([orderTypes.GET_PAYMENT_REPORT_CSV])(state),
  )

  const canNullify = useSelector(state => isAllowed(state, [12053]))
  const canDiffPay = useSelector(state => isAllowed(state, [12054]))
  const canChangeNumber = useSelector(state => isAllowed(state, [12055]))
  const canPayCxc = useSelector(state => isAllowed(state, [12056]))
  const canNullifyPhysical = useSelector(state => isAllowed(state, [12057]))
  const canAccess = useSelector(state => isAllowed(state, [12011]))
  const canSeeGeneral = useSelector(state => isAllowed(state, [12059]))
  const canPaymentsCSV = useSelector(state => isAllowed(state, [12060]))
  const canSeeAllCxc = useSelector(state => isAllowed(state, [12063]))
  const canConfirmOwn = useSelector(state => isAllowed(state, [12064]))
  const canConfirmOthers = useSelector(state => isAllowed(state, [12065]))

  const canUseFilters = isAdmin || canSeeAllCxc

  const [showNullify, setShowNullify] = useState({
    show: false,
    item: null,
  })

  useEffect(() => {
    if (showNullify.item) dispatch(getPaymentsOrder(showNullify.item.id))
  }, [showNullify])

  const [selected, setSelected] = useState({ id: null })
  const [alert, setAlert] = useState({ title: 'title' })
  const [actions, setActions] = useState({
    create: false,
    excel: false,
    nullifyNumber: null,
    isExist: false,
    nullify: false,
    pay: false,
    csv: false,
  })
  const [paymentModal, setPaymentModal] = useState({ show: false, amount: 0, order: {} })
  const [clientModal, setClientModal] = useState({ show: false, amount: 0, client: null })
  const [paymentDeferred, setPaymentDeferred] = useState({
    show: false,
    paymentDate: new Date(new Date().setMinutes(420)),
  })
  const [showCategorization, setShowCategorization] = useState({
    show: false,
    filter: false,
    order: null,
  })
  const [selectedCategories, setSelectedCategories] = useState([])
  const [ordersSelected, setOrdersSelected] = useState([])
  const [paymentSelected, setPaymentSelected] = useState([])
  const [countsSelected, setCountsSelected] = useState([])
  const [payOrderModal, setPayOrderModal] = useState({ show: false })
  const [modalNumber, setModalNumber] = useState({ show: false, id: null })
  const [modalArchived, setArchived] = useState({ show: false })

  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    start: null,
    end: null,
    categories: '',
    client: true,
    payments: true,
    orders: true,
    skip: 0,
    size: 10,
    search: null,
    skipO: 0,
    sizeO: 10,
    searchO: null,
    clientId: null,
    dealerMan: defaultValue,
    deliveryMan: defaultValue,
    state: defaultNullValue,
  })
  const [all, setAll] = useState(false)
  const [allA, setAllA] = useState(false)

  const [searchClient, setSearch] = useState(null)

  const [paymentsCSVShow, setPaymentCSVShow] = useState(false)
  const [paymentTicket, setPaymentTicket] = useState({})
  const [clients, setClients] = useState([])

  useEffect(() => {
    const cpc = allClients
      .filter(x => x.balance > 0 || (x.balance > 0 && x.vouchers > 0))
      .map(y => {
        return { ...y, type: 1 }
      })
    const vouchers = allClients
      .filter(s => s.vouchers > 0 && s.balance === 0)
      .map(y => {
        return { ...y, type: 2 }
      })

    setClients(cpc.concat(vouchers))
  }, [allClients])

  useEffect(() => {
    dispatch(getAllCategorizations(5))
    dispatch(getUsersChildrenByModule(1000, true))
  }, [])

  useEffect(() => {
    if (allRoles.length === 0) return
    if (textFilter === '') setTextFilter(JSON.stringify(filters))
  }, [allRoles])

  useEffect(() => {
    if (window.location.href.includes('/pagos') && props.match.params.client) {
      const client = clients.find(c => c.id === parseInt(props.match.params.client))
      if (client) {
        onRowClicked(client)
        setSearch(client.name)
      }
    }
  }, [loadingClients])

  useEffect(() => {
    if (loadingExcel) setActions({ ...actions, excel: true })
    else if (actions.excel && hasErrorExcel)
      setAlert({
        ...handlerError('Error al generar el excel'),
        onConfirm: () => {
          setAlert({ show: false })
        },
      })
  }, [loadingExcel])

  useEffect(() => {
    if (loadingReportCSV) setActions({ ...actions, csv: true })
    else if (actions.csv) {
      setActions({ ...actions, email: false })
      if (hasErrorReportCSV)
        setAlert({
          ...handlerError(errorReportCSV.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
    }
  }, [loadingReportCSV])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp()
  }, [textFilter])

  useEffect(() => {
    setFilters({
      ...filters,
      categories: selectedCategories.map(p => p.id).join(','),
      client: true,
      orders: true,
      payments: true,
      skip: 0,
    })
  }, [selectedCategories])

  useEffect(() => {
    if (loadingEditArchive) setActions({ ...actions, edit: true })
    else if (actions.edit) {
      setActions({ ...actions, edit: false })
      if (hasErrorEditArchive) setInfoAlert(errorEditArchive.message, false)
      else {
        dispatch(getArchivedPayment())
        setPaymentSelected([])
        setInfoAlert('Operación realizada exitosamente', true)
      }
    }
  }, [loadingEditArchive])

  useEffect(() => {
    if (loadingSegmentationReport) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      if (errorSegmentationReport)
        setAlert({
          ...handlerError(errorSegmentationReport.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
    }
  }, [loadingSegmentationReport])

  useEffect(() => {
    if (loadingMassive) setActions({ ...actions, edit: true })
    else if (actions.edit) {
      setActions({ ...actions, edit: false })
      if (hasErrorMassive) setInfoAlert(errorMassive.message, false)
      else {
        setOrdersSelected([])
        setInfoAlert('Órdenes archivadas exitosamente', true)
      }
    }
  }, [loadingMassive])

  useEffect(() => {
    if (loadingConfirm) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorConfirm) setInfoAlert(errorConfirm.message, false)
      else {
        setInfoAlert('Abono confirmado exitosamente', true)
        dispatch(setModalOrderPayment({ show: false }))
      }
    }
  }, [loadingConfirm])

  useEffect(() => {
    if (loadingCreate) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorCreate) setInfoAlert(errorCreate.message, false)
      else setInfoAlert('Abono creado exitosamente', true)
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingNullifyOrder) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorNullifyOrder) setInfoAlert(errorNullifyOrder.message, false)
      else {
        setShowNullify({ show: false, item: null })
        setInfoAlert('Orden anulada exitosamente', true)
      }
    }
  }, [loadingNullifyOrder])

  useEffect(() => {
    if (loadingUpdateParams) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })
      if (hasErrorUpdateParams) setInfoAlert(errorUpdateParams.message, false)
      else setInfoAlert('Orden actualizada exitosamente', true)
    }
  }, [loadingUpdateParams])

  useEffect(() => {
    setTimeout(() => {
      if (selected.id && !loadingConfirm && hasErrorConfirm)
        setInfoAlert(errorConfirm.message || 'Ha ocurrido un error inesperado', false)
    }, 200)
  }, [hasErrorConfirm])

  useEffect(() => {
    setTimeout(() => {
      if (selected.id && !loadingDelete && !hasErrorDelete)
        setInfoAlert('Orden de pago actualizada satisfactoriamente.', true)
    }, 200)
  }, [loadingDelete])

  useEffect(() => {
    setTimeout(() => {
      if (selected.id && !loadingDelete && hasErrorDelete)
        setInfoAlert(errorDelete.message || 'Ha ocurrido un error inesperado', false)
    }, 200)
  }, [hasErrorDelete])

  useEffect(() => {
    setTimeout(() => {
      if (selected.id && !loadingDeferredPayment && !hasErrorDeferredPayment)
        setInfoAlert('Orden de pago diferido satisfactoriamente', true)
    }, 200)
  }, [loadingDeferredPayment])

  useEffect(() => {
    setTimeout(() => {
      if (selected.id && !loadingDeferredPayment && hasErrorDeferredPayment)
        setInfoAlert(
          errorDeferredPayment.message || 'Ha ocurrido un error inesperado',
          false,
        )
    }, 200)
  }, [hasErrorDeferredPayment])

  useEffect(() => {
    setTimeout(() => {
      if (selected.id && !loadingNullifyPayment && !hasErrorNullifyPayment)
        setInfoAlert('Orden de pago anulada satisfactoriamente', true)
    }, 200)
  }, [loadingNullifyPayment])

  useEffect(() => {
    setTimeout(() => {
      if (selected.id && !loadingNullifyPayment && hasErrorNullifyPayment)
        setInfoAlert(
          errorNullifyPayment.message || 'Ha ocurrido un error inesperado',
          false,
        )
    }, 200)
  }, [hasErrorNullifyPayment])

  useEffect(() => {
    if (loadingNullifyReceipt) setActions({ ...actions, nullifyReceipt: true })
    else if (actions.nullifyReceipt) {
      setActions({ ...actions, nullifyReceipt: false })
      if (hasErrorNullifyReceipt)
        setAlert({
          ...handlerError(
            errorNullifyReceipt.message || 'Ha ocurrido un error inesperado',
          ),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else setInfoAlert('Número de recibo anulado satisfactoriamente', true)
    }
  }, [loadingNullifyReceipt])

  useEffect(() => {
    if (loadingPaymentReceipt) setActions({ ...actions, isExist: true })
    else if (actions.isExist && actions.nullifyNumber) {
      setActions({ ...actions, isExist: false, nullifyNumber: null })
      if (paymentReceipt) {
        setAlert({
          ...handlerError(
            `El número de recibo ya esta en uso. #${actions.nullifyNumber}`,
          ),
          onConfirm: () => nullifyPaymentNumber(),
        })
      } else {
        dispatch(onNullifySpecificPaymentNumber(actions.nullifyNumber))
      }
    }
  }, [loadingPaymentReceipt])

  useEffect(() => {
    if (loadingNullifyNumber) setActions({ ...actions, nullify: true })
    else if (actions.nullify) {
      setActions({ ...actions, nullify: false })
      if (hasErrorNullifyNumber)
        setAlert({
          ...handlerError(hasErrorNullifyNumber.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else setInfoAlert('Recibo anulado satisfactoriamente', true)
    }
  }, [loadingNullifyNumber])

  useEffect(() => {
    if (Object.entries(paymentTicket).length !== 0) {
      const container = document.getElementById('ticketpayment').innerHTML
      printTicket(container, null, null, null)
    }
  }, [paymentTicket])

  const setInfoAlert = (message, success) =>
    setAlert({
      ...(success ? handlerSuccess(message) : handlerError(message)),
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        setSelected({ id: null })
        if (success) {
          setAll(false)
          setAllA(false)
          setPaymentModal({ ...paymentModal, show: false })
          setPaymentDeferred({ ...paymentDeferred, show: false })
          setModalNumber({ ...modalNumber, show: false })
          setClientModal({ show: false, amount: 0, client: null })
          setPayOrderModal({ show: false })
          setFilters({
            ...filters,
            client: true,
            orders: true,
            payments: true,
            up: new Date().valueOf(),
          })
        }
      },
    })

  const documentTypeByReceipt = {
    1: '5',
    2: '6',
    3: '1',
    4: '1',
  }

  const setUp = () => {
    const {
      start,
      end,
      categories,
      client,
      orders,
      payments,
      skip,
      size,
      search,
      skipO,
      sizeO,
      searchO,
      clientId,
      dealerMan,
      deliveryMan,
      state,
    } = filters

    const vendorId = (canUseFilters ? dealerMan.value : user.id) || null
    const delivererId = (canUseFilters ? deliveryMan.value : user.id) || null
    const userId = canUseFilters ? undefined : user.id

    const params = {}
    if (!isAdmin) params.perUser = user.id
    params.client = selected.id
    params.categories = categories

    if (client)
      dispatch(
        getAllClients({
          sort: 1,
          categorization: categories,
          attendant: userId,
        }),
      )

    if (payments)
      dispatch(
        getPaymentOrders({
          ...params,
          skip,
          size,
          search,
          start,
          end,
          client: clientId,
          user: userId,
          state: state?.value,
        }),
      )

    if (orders)
      dispatch(
        getOrderBalanceByCustomers({
          client: clientId,
          start,
          end,
          payment: 2,
          type: 1,
          stateId: -2, // Ventas pendientes
          skip: skipO,
          size: sizeO,
          search: searchO,
          categories,
          vendor: vendorId,
          deliverer: delivererId,
        }),
      )

    dispatch(
      getOrdersSummary({
        client: clientId,
        payment: 2,
        start,
        end,
        type: 1,
        stateId: -3,
        vendor: vendorId,
        deliverer: delivererId,
      }),
    )
  }

  const nullifyOrder = detail => {
    setAlert({
      show: true,
      type: 'warning',
      title: 'Anulación de Orden',
      text: '¿Desea anular la orden? \n Se anularan todos los abonos y depósitos realizados asociados a la venta. Esta acción no podrá ser revertida',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Anular',
      confirmButtonColor: '#B35796',
      onCancel: () => setAlert({ ...alert, show: false }),
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        dispatch(
          cancelInvoiceAndOrder(
            1,
            detail.id,
            detail.status,
            null,
            true,
            false,
            false,
            detail.object,
          ),
        )
      },
    })
  }

  const onRowClicked = data => {
    let clientId = null
    clients.forEach(c => {
      if (c.id === selected.id) {
        setSelected({ id: null, client: null })
      } else if (c.id === data.id) {
        setSelected({ id: data.id, client: data.id })
        clientId = data.id
      }
    })
    setCountsSelected([])
    setFilters({
      ...filters,
      client: false,
      orders: true,
      payments: true,
      skip: 0,
      skipO: 0,
      clientId,
    })
  }

  const onOrderRowClicked = data => {
    setOrdersSelected(
      ordersSelected.every(s => s !== data.id)
        ? [...ordersSelected, data.id]
        : [...ordersSelected.filter(f => f !== data.id)],
    )
  }

  const onPaymentRowClicked = data => {
    setPaymentSelected(
      paymentSelected.every(s => s !== data.id)
        ? [...paymentSelected, data.id]
        : [...paymentSelected.filter(f => f !== data.id)],
    )
  }

  const onCountRowClicked = data => {
    if (selected.id === null) return

    setCountsSelected(
      countsSelected.every(s => s.id !== data.id)
        ? [...countsSelected, { ...data, amount: 0 }]
        : [...countsSelected.filter(f => f.id !== data.id)],
    )
  }

  const assignCategorizationToOrder = categorization => {
    const array = ordersSelected.length > 0 ? ordersSelected : [showCategorization.order]

    dispatch(
      assignCategorizationToMultipleEntities(
        null,
        categorization,
        array,
        Boolean(showCategorization.order),
        false,
        17,
      ),
    )
    setTimeout(() => {
      if (ordersSelected.length === 0)
        dispatch(getCustomCategorizationsByEntity(showCategorization.order, false, 17))
      dispatch(changeCategorization(array, categorization, !!showCategorization.order))
    }, 500)
  }

  const renderUserRow = (item, index) => {
    const isActive = item.id === selected.id ? 'active' : ''

    return (
      <div className={`b-user-item-v2 ${isActive}`} onClick={() => onRowClicked(item)}>
        <div
          className={`mini `}
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
          <input
            type={'checkbox'}
            name={index}
            checked={item.id === selected.id}
            onChange={() => undefined}
          />
        </div>
        <div style={{ width: '100%' }} className={`${isActive}`}>
          <div className={`b-user-item ${isActive}`} key={`${index}`}>
            <div
              className={'justify-content-start align-items-start bu-en'}
              style={{ textAlign: 'left' }}>
              <div className={`b-user-name ${isActive}`}>{item.companyName} </div>
              <div className={`b-user-email ${isActive}`}>
                {item.nit ? item.nit : 'CF'}
              </div>
              <div className={`b-user-email ${isActive}`}>{item.code}</div>
            </div>
            {item.type === 1 && (
              <div
                className={'d-flex row align-items-center mr-2 bu-b'}
                style={{ textAlign: 'right' }}>
                {canPayCxc && (
                  <IconButton
                    icon={faMoneyCheckAlt}
                    tooltip={'Abonar'}
                    style={{ marginRight: 4 }}
                    onClick={e => {
                      e.stopPropagation()
                      setClientModal({ show: true, client: item, clientId: item.id })
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className={`b-user-item ${isActive}`}>
            <div
              className={'justify-content-start align-items-start bu-en'}
              style={{ textAlign: 'left' }}>
              <div className={`b-user-email `}>CPC</div>
              <div className={`b-user-email `}>Saldo a favor</div>
            </div>
            <div className={'mr-2 bu-b'} style={{ textAlign: 'right' }}>
              <div className={`b-user-email ${isActive}`}>{toMoney(item.balance)}</div>
              <div className={`b-user-email ${isActive}`}>{toMoney(item.vouchers)}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const filterClientSearch = item => {
    if (!searchClient) return true
    const object = { companyName: item.companyName, nit: item.nit, code: item.code }
    const exp = new RegExp(searchClient.toUpperCase(), 'g')
    return Object.keys(object).some(p =>
      JSON.stringify(object[p]).toUpperCase().match(exp),
    )
  }

  const editOrders = (type, element) => {
    if (element) dispatch(editArchivedPayment([element], { all: allA, type }))
    else dispatch(editArchivedPayment(paymentSelected, { all: allA, type }))
  }

  const nullifyPaymentNumber = () => {
    setAlert({
      show: true,
      type: 'input',
      inputPlaceholder: 'recibo',
      title: 'Anulación de recibo',
      text: '¿Desea anular un número de recibo específico? Los nuevos abonos/recibos ya no podrán llevar el número de recibo indicado. El recibo anulado podrá ser visualizado en la papelera de archivados.',
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      confirmButtonText: 'Anular',
      confirmButtonColor: '#B35796',
      onCancel: () => setAlert({ ...alert, show: false }),
      onConfirm: text => {
        if (!text || text === '') return
        setAlert({ ...alert, show: false })
        setActions({ ...actions, nullifyNumber: text })
        dispatch(getPaymentReceipt(text))
      },
    })
  }

  const today = new Date()
  const paymentsHeaders = [
    {
      show: true,
      label: 'No. Recibo',
      value: ['number'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Cliente',
      value: ['client', 'name'],
      type: 'text',
      className: 'medium',
      custom: item => (item.client ? item.client.name : 'Desconocido'),
    },
    {
      show: true,
      label: 'Fecha',
      value: ['enabledAt'],
      type: 'date',
      className: 'mini',
    },
    {
      show: true,
      label: 'Fecha de Pago',
      value: ['paymentAt'],
      type: 'date',
      className: 'mini',
      custom: item => (
        <div
          className={
            item.paymentAt !== null && today.valueOf() > item.paymentAt ? 'red' : ''
          }>
          {item.paymentAt !== null ? formatDateFromMillis(item.paymentAt) : '---'}
        </div>
      ),
    },
    {
      show: true,
      label: `Total (${currentCurrency.symbol})`,
      value: ['total'],
      type: 'currency',
      className: 'mini',
    },
    {
      show: true,
      label: `Saldo pendiente (${currentCurrency.symbol})`,
      value: ['balance'],
      type: 'currency',
      className: 'mini',
    },
    {
      show: true,
      label: 'Responsable',
      value: ['responsible', 'name'],
      type: 'currency',
      className: 'medium',
      custom: item => (item.responsible ? item.responsible.name : 'Desconocido'),
    },
    {
      show: true,
      label: '',
      className: 'mini',
      config: true,
      custom: item => (
        <DropdownCustom
          disabled={loadingConfirm || loadingDelete}
          items={[
            {
              title: 'Ver detalle',
              show: true,
              action: () => dispatch(onSetModalOrder(item.id)),
            },
            {
              title: 'Abonar',
              show: item.balance > 0 && canPayCxc,
              action: () => {
                setPaymentModal({
                  show: true,
                  order: item,
                  amount: 0,
                  client: item.client,
                  id: item.client?.id,
                })
              },
            },
            {
              title: 'Anular',
              show: canNullify,
              action: () => {
                setShowNullify({ show: true, item })
              },
            },
          ]}
        />
      ),
    },
  ]

  const salesHeaders = [
    {
      label: '',
      select: false,
      checked: all && ordersSelected.length === 0,
      className: 'mini',
    },
    {
      show: true,
      label: 'No. Recibo',
      value: ['number'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Estado',
      value: ['statusData', 'name'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Pago',
      value: ['paymentTypeData', 'name'],
      type: 'text',
      className: 'mini',
      custom: item => (item.paymentTypeData ? item.paymentTypeData.name : 'Desconocido'),
    },
    {
      show: true,
      label: `Fecha de creación`,
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    {
      show: true,
      label: `Fecha de pago`,
      value: ['paymentAt'],
      type: 'date',
      className: 'mini',
    },
    {
      show: true,
      label: `Fecha de confirmación`,
      value: ['date'],
      type: 'date',
      className: 'mini',
      custom: item => (item.date ? formatDateFromMillis(item.date) : 'Sin confirmar'),
    },
    {
      show: true,
      label: 'Cliente',
      value: ['client', 'name'],
      type: 'text',
      className: 'medium',
      custom: item => (item.client ? item.client.name : 'Desconocido'),
    },
    {
      show: true,
      label: 'Responsable',
      value: ['createdData', 'name'],
      type: 'text',
      className: 'medium',
      custom: item => (item.createdData ? item.createdData.name : 'Desconocido'),
    },
    {
      show: true,
      label: `Monto (${currentCurrency.symbol})`,
      value: ['balance'],
      type: 'currency',
      className: 'mini',
    },
    {
      show: true,
      label: '',
      className: 'mini',
      config: true,
      custom: item =>
        (loadingConfirm || loadingDelete) && selected.id && selected.id === item.id ? (
          <Icon icon={faSyncAlt} spin={loadingConfirm || loadingDelete} />
        ) : (
          <DropdownCustom
            disabled={loadingConfirm || loadingDelete || loadingNullifyPayment}
            loading={(loadingNullifyPayment || loadingPDF) && item.id === selected.id}
            items={[
              {
                title: 'Confirmar pago',
                show:
                  item.status === 1 &&
                  (item.createdBy === user.id ? canConfirmOwn : canConfirmOthers),
                action: () =>
                  setAlert({
                    show: true,
                    type: 'info',
                    title: `¿Confirmar orden de pago #${item.number}?`,
                    showCancelButton: true,
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      setSelected(item)
                      setAlert({ ...alert, show: false })
                      dispatch(onConfirmOrderPayment(item.id, item.client))
                    },
                  }),
              },
              {
                title: 'Eliminar pago',
                show: item.status === 1 && canAccess,
                action: () =>
                  setAlert({
                    show: true,
                    type: 'warning',
                    title: `¿Eliminar orden de pago #${item.number}?`,
                    showCancelButton: true,
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      setSelected(item)
                      setAlert({ ...alert, show: false })
                      dispatch(deleteSingleOrder(item.id))
                    },
                  }),
              },
              {
                title: 'Ver detalle',
                divide: item.status === 1 && canAccess,
                show: true,
                action: () => {
                  dispatch(setModalOrderPayment({ show: true, ...item }))
                },
              },
              {
                title: 'Archivar orden',
                show:
                  (item.status === 1 || item.status === 13 || item.status === 11) &&
                  canAccess,
                action: () =>
                  setAlert({
                    show: true,
                    type: 'info',
                    title: `¿Archivan orden de pago #${item.number}?`,
                    text: 'Los pagos archivados podrán visualizarse desde la papelera de pagos archivados',
                    showCancelButton: true,
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      editOrders(1, item.id)
                    },
                  }),
              },
              {
                title: 'Anular pago',
                show: item.status === 2 && canNullify,
                action: () =>
                  setAlert({
                    show: true,
                    type: 'info',
                    title: `¿Desea anular orden de pago #${item.number}?`,
                    text: 'Este proceso no podrá ser revertido.',
                    showCancelButton: true,
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      setSelected(item)
                      setAlert({ ...alert, show: false })
                      dispatch(onNullifyPayment(item.id))
                    },
                  }),
              },
              {
                title: 'Diferir pago',
                show: item.status === 2 && canDiffPay,
                action: () => {
                  setSelected(item)
                  setPaymentDeferred({ ...paymentDeferred, show: true })
                },
              },
              {
                show: true,
                title: 'Descargar PDF',
                action: () => dispatch(printOrder(item.id, item.number)),
              },
              {
                show: true,
                title: 'Imprimir ticket',
                action: async () => {
                  const newValue = JSON.parse(JSON.stringify(item))
                  const result = await OrderService.getOrdersToPayByOrder(item.id)
                  const deposit =
                    item.deposit != null
                      ? await utilitiesServices.getInfoReference(2, 4, item.deposit)
                      : null
                  setPaymentTicket({
                    ...newValue,
                    payments: result,
                    depositData: deposit,
                  })
                },
              },
              {
                show:
                  item.status === 2 &&
                  item.receiptCertificate !== null &&
                  item.receiptCertificate !== 0,
                title: 'Ver recibo',
                action: () => {
                  setSelected(item)
                  dispatch(
                    onPrintInvoice({
                      active: true,
                      orderId: item.id,
                      defaultName: item.number,
                      print: false,
                      documentType: documentTypeByReceipt[item.receiptCertificate],
                    }),
                  )
                },
              },
              {
                title: 'Anular recibo',
                show: item.status === 2 && item.receiptCertificate && canNullify,
                action: () =>
                  setAlert({
                    show: true,
                    type: 'info',
                    title: `¿Desea anular recibo #${item.number}?`,
                    text: 'No se podrá generar un nuevo recibo para la orden de pago',
                    showCancelButton: true,
                    onCancel: () => setAlert({ ...alert, show: false }),
                    onConfirm: () => {
                      setSelected(item)
                      setAlert({ ...alert, show: false })
                      dispatch(onNullifyReceiptPayment(item.id))
                    },
                  }),
              },

              {
                title: 'Cambiar número de recibo',
                show: canChangeNumber,
                action: () => {
                  setModalNumber({ show: true, id: item.id, number: item.number })
                },
              },
              {
                title: 'Agregar categorías',
                show: canAccess,
                action: () => {
                  dispatch(getAllCategorizations(17))
                  dispatch(getCustomCategorizationsByEntity(item.id, false, 17))
                  setShowCategorization({
                    show: true,
                    filter: false,
                    order: item.id,
                    type: categoryType.GENERAL,
                  })
                },
              },
            ]}
          />
        ),
    },
  ]

  const archivedHeaders = [
    {
      label: '',
      select: false,
      checked: allA && paymentSelected.length === 0,
      className: 'mini',
    },
    {
      show: true,
      label: 'No. Recibo',
      value: ['number'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Estado',
      value: ['statusData', 'name'],
      type: 'text',
      className: 'mini',
    },
    {
      show: true,
      label: 'Cliente',
      value: ['clientData', 'companyName'],
      type: 'text',
      className: 'medium',
      custom: item =>
        item.clientData ? item.clientData.companyName : 'Sin cliente asignado',
    },
    {
      show: true,
      label: 'Encargado',
      value: ['createdData', 'name'],
      type: 'text',
      className: 'medium',
      custom: item => item.createdData.name || 'Desconocido',
    },
    {
      show: true,
      label: 'Fecha',
      value: ['createdAt'],
      type: 'date',
      className: 'mini',
    },
    {
      show: true,
      label: `Monto (${currentCurrency.symbol})`,
      value: ['balance'],
      type: 'currency',
      className: 'mini',
    },
    {
      show: true,
      label: '',
      className: 'mini',
      config: true,
      custom: item => (
        <DropdownCustom
          disabled={loadingConfirm || loadingDelete}
          items={[
            {
              title: 'Asignar categorías',
              show: true,
              action: () => {
                dispatch(getCustomCategorizationsByEntity(item.id, false, 17))
                setShowCategorization({ show: true, order: item.id, filter: false })
              },
            },
            { title: 'Recuperar', show: true, action: () => editOrders(1, item.id) },
            {
              title: 'Eliminar',
              show: item.balance > 0,
              action: () =>
                setAlert({
                  show: true,
                  type: 'info',
                  title: `¿Desea eliminar la orden de pago #${item.number}?`,
                  text: `Esta acción no podrá ser revertida`,
                  showCancelButton: true,
                  onCancel: () => setAlert({ ...alert, show: false }),
                  onConfirm: () => {
                    editOrders(2, item.id)
                  },
                }),
            },
          ]}
        />
      ),
    },
  ]

  const downloadReportSegmented = segmentByClient => {
    const { start, end } = filters

    const params = {
      reportType: segmentationReportTypeEnum.BY_CPC.id,
      start,
      end,
      segmentByClient,
      categories: selectedCategories.map(p => p.id).join(),
      clients: selected.id > 0 ? selected.id : undefined,
    }

    dispatch(getSegmentationReportOptExcel(params, 'cuentas por cobrar'))
  }

  const filterPayments = (
    <Row>
      <Col xl={12}>
        <Select
          label={'Filtrar por estado'}
          value={filters.state}
          options={[defaultNullValue, ...statusList]}
          onChange={value =>
            setFilters({
              ...filters,
              state: value,
              client: false,
              orders: false,
              payments: true,
            })
          }
        />
      </Col>
    </Row>
  )

  return (
    <div>
      <Title title={'Estado de cuenta de clientes'} />
      <Row>
        <Col xl={4} lg={4} md={5} sm={12} xs={12}>
          {canSeeGeneral && (
            <Card>
              <div className={'justify-center column'}>
                <br />
                <span>Total de cuentas por cobrar</span>
                <Money component="h4">{cxcSummary.ordersTotal}</Money>
                <br />
                <span>Abonos recibidos</span>
                <Money component="h4">{cxcSummary.ordersPaid}</Money>
                <br />
                <span>Saldo pendiente</span>
                <Money component="h4">{cxcSummary.ordersPending}</Money>
                <br />
              </div>
            </Card>
          )}

          <Card title={'Clientes'}>
            <TableV2
              loading={loadingClients}
              items={clients.filter(filterClientSearch).map(item => ({
                id: item.id,
                companyName: item.companyName,
                balance: item.balance,
                nit: item.nit,
                code: item.code,
                type: item.type,
                vouchers: item.vouchers,
              }))}
              filterC
              renderRow={renderUserRow}
              handleChange={value => {
                setSearch(value)
              }}
            />
          </Card>
        </Col>
        <Col xl={8} lg={8} md={7} sm={12} xs={12}>
          <Card white title={'Filtros'}>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => {
                setFilters({
                  ...filters,
                  start,
                  end,
                  client: false,
                  orders: true,
                  payments: true,
                  skip: 0,
                })
              }}
            />
            <Row>
              <Col xs={12}>
                <div
                  style={{
                    margin: '15px 5px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                  }}>
                  <Button
                    color={'primary'}
                    onClick={() => {
                      setShowCategorization({
                        show: true,
                        filter: true,
                        type: categoryType.CLIENTS,
                      })
                      dispatch(getAllCategorizations(5))
                    }}>
                    Filtrar por categorías
                  </Button>
                  {selectedCategories.map(p => (
                    <div className={'user-tag product-t'} key={p.id}>
                      <label className={'label-user-tag'}>{p.name}</label>
                      <ButtonIcon
                        className={'delete-user-tag d-product-t'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() =>
                          setSelectedCategories(
                            selectedCategories.filter(f => f.id !== p.id),
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Card>
          <CustomTabs
            setKey={() => {
              setCountsSelected([])
              setOrdersSelected([])
            }}
            items={[
              {
                title: `Cuentas por cobrar (${totalCxc})`,
                info: 'Órdenes de venta pendientes de pago',
                component: (
                  <TableV2
                    mobileAuto={true}
                    storageKey={`payment-${user.id}`}
                    headers={paymentsHeaders}
                    loading={loadingSaleOrders}
                    items={saleOrders}
                    total={totalCxc}
                    getPagination={(skipO, sizeO, searchO) =>
                      setFilters({
                        ...filters,
                        skipO,
                        sizeO,
                        searchO,
                        client: false,
                        orders: true,
                        payments: false,
                      })
                    }
                    handleChange={searchO =>
                      setTimeout(() => {
                        setFilters({
                          ...filters,
                          searchO,
                          client: false,
                          orders: true,
                          payments: false,
                        })
                      }, 1000)
                    }
                    isSelected={item => {
                      return countsSelected.filter(s => s.id === item.id).length !== 0
                    }}
                    onClickTr={(e, item) => onCountRowClicked(item)}
                    customFilter={
                      <>
                        <Col xl={6} md={6} sm={6} xs={12}>
                          <Select
                            label={'Creado por'}
                            info={'Filtra las órdenes por el usuario que la creo'}
                            value={filters.dealerMan}
                            options={[defaultValue, ...users]}
                            onChange={value => {
                              setFilters({
                                ...filters,
                                dealerMan: value,
                                client: false,
                                orders: true,
                                payments: false,
                              })
                            }}
                          />
                        </Col>
                        <Col xl={6} md={6} sm={6} xs={12}>
                          <Select
                            label={'Entregado por'}
                            info={'Filtra las órdenes por el usuario que la entrego'}
                            value={filters.deliveryMan}
                            options={[defaultValue, ...users]}
                            onChange={value => {
                              setFilters({
                                ...filters,
                                deliveryMan: value,
                                client: false,
                                orders: true,
                                payments: false,
                              })
                            }}
                          />
                        </Col>
                      </>
                    }
                  />
                ),
              },
              {
                title: `Órdenes de pago (${totalOrders})`,
                info: 'Órdenes de pago',
                component: (
                  <TableV2
                    storageKey={`paymentOrders-${user.id}`}
                    headers={salesHeaders}
                    onCheck={value => {
                      setAll(value)
                      setOrdersSelected([])
                    }}
                    customFilter={filterPayments}
                    items={paymentOrders}
                    total={totalOrders}
                    getItems={item => {
                      if (item === null) setOrdersSelected([])
                      else onOrderRowClicked(item)
                    }}
                    mobileAuto={true}
                    loading={loadingPaymentOrders}
                    getPagination={(skip, size, search) =>
                      setFilters({
                        ...filters,
                        skip,
                        size,
                        search,
                        client: false,
                        orders: false,
                        payments: true,
                      })
                    }
                    handleChange={search =>
                      setTimeout(() => {
                        setFilters({
                          ...filters,
                          search,
                          client: false,
                          orders: false,
                          payments: true,
                        })
                      }, 1000)
                    }
                    isSelected={item => {
                      const select =
                        ordersSelected.filter(s => s === item.id).length !== 0
                      return select && all ? false : all || select
                    }}
                    onClickTr={(e, item) => onOrderRowClicked(item)}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>

      <Modal
        show={clientModal.show}
        size={'md'}
        centered
        onHide={() =>
          setClientModal({
            ...clientModal,
            show: false,
            amount: 0,
            client: null,
            notCxc: false,
          })
        }>
        <Modal.Header>
          <Modal.Title>Realizar un pago</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateOrderPayment
            show={clientModal.show}
            clientId={clientModal.clientId}
            balance={clientModal.client ? clientModal.client.balance : 0}
            loading={loadingCreate}
            client={{ id: clientModal.client ? clientModal.client.id : 0 }}
            onCreateOrder={payment => {
              dispatch(onCreateOrderPayment(payment))
            }}
            notCxc={clientModal.notCxc}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={paymentModal.show}
        size={'lg'}
        centered
        onHide={() =>
          setPaymentModal({ ...paymentModal, show: false, amount: 0, client: null })
        }>
        <Modal.Header closeButton>
          <Modal.Title>Realizar un pago</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateOrderPayment
            show={paymentModal.show}
            clientId={paymentModal.id}
            balance={paymentModal.order.balance || 0}
            loading={loadingConfirm}
            client={paymentModal.client}
            onCreateOrder={payment => {
              setSelected({ id: paymentModal.order.id })
              const object = {
                ...payment,
                client: paymentModal.client.id,
                payments: [{ orderId: paymentModal.order.id, amount: payment.amount }],
              }
              dispatch(onCreatePaymentSpecificOrder(paymentModal.order.id, object))
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={paymentDeferred.show}
        centered
        size={'md'}
        onHide={() => setPaymentDeferred({ ...paymentDeferred, show: false })}>
        <Modal.Header>
          <Modal.Title>Diferir pago</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={'column'}>
            <h4>
              ¿Desea diferir este pago? Esta acción afectara al balance del usuario
              responsable del pago
            </h4>
            <h5>
              Solo es posible diferir un pago una sola vez, el cliente asociado al pago
              entrara en estado moroso
            </h5>
            <hr />
            <CustomDate
              label={'Programar para:'}
              value={paymentDeferred.paymentDate}
              disabledDays={{
                before: new Date(new Date().setMinutes(420)),
                after: new Date(new Date().setMinutes(10080)),
              }}
              onDayChange={e =>
                setPaymentDeferred({ ...paymentDeferred, paymentDate: e })
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingDeferredPayment}
              variant={'secondary'}
              onClick={() => setPaymentDeferred({ ...paymentDeferred, show: false })}>
              Cerrar
            </Button>
            <Button
              loading={loadingDeferredPayment}
              variant={'info'}
              icon={faCheckDouble}
              right
              onClick={() => {
                dispatch(
                  onDeferredPayment(selected.id, paymentDeferred.paymentDate.valueOf()),
                )
              }}>
              Diferir pago
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={payOrderModal.show}
        centered
        size={'md'}
        onHide={() => setPayOrderModal({ ...payOrderModal, show: false })}>
        <Modal.Header>
          <Modal.Title>Abonar</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateOrderPayment
            show={payOrderModal.show}
            clientId={payOrderModal.id}
            balance={payOrderModal.balance || 0}
            client={payOrderModal.client}
            loading={loadingConfirm}
            multiple
            orders={payOrderModal.orders}
            onCreateOrder={payment => {
              setSelected({ id: paymentModal.order.id })
              let amount = 0
              payment.payments.forEach(s => {
                amount += s.amount
              })
              const object = { ...payment, amount, client: selected.client }
              dispatch(onCreatePaymentSpecificOrder(paymentModal.order.id, object))
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalNumber.show}
        centered
        size={'md'}
        onHide={() => setModalNumber({ show: false, id: null })}>
        <Modal.Header>
          <Modal.Title>
            {modalNumber.isNullify
              ? 'Anulación de número de recibo'
              : 'Cambiar número de recibo'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            {modalNumber.isNullify && (
              <Col xl={12}>
                <div className={'column'}>
                  <div className={'b-user-name'}>
                    ¿Desea anular un número de recibo específico?
                  </div>
                  <div className={'b-user-email'}>
                    Los nuevos abonos/recibos ya no podrán llevar el número de recibo
                    indicado. El recibo anulado podrá ser visualizado en la papelera de
                    archivados.
                  </div>
                </div>
              </Col>
            )}
            <Col xl={12}>
              <FormText
                id={'receipt'}
                label={'No. de Recibo'}
                placeholder={'pj. 12345'}
                name={'receipt'}
                value={modalNumber.number}
                error={
                  modalNumber.number && paymentReceipt
                    ? 'Nota: El número de referencia ya fue ingresado anteriormente.'
                    : null
                }
                onChange={({ target }) => {
                  const { value } = target
                  setModalNumber({ ...modalNumber, number: value })
                  if (value != null && value !== '') {
                    clearTimeout(interval)
                    interval = setTimeout(() => {
                      dispatch(
                        getPaymentReceipt(target.value, { paymentId: modalNumber.id }),
                      )
                    }, 750)
                  }
                }}
              />
            </Col>
            {modalNumber.isNullify && (
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <CustomDate
                      label={'Fecha de registro'}
                      value={modalNumber.date}
                      onDayChange={e => setModalNumber({ ...modalNumber, date: e })}
                    />
                  </Col>
                  <Col xl={12}>
                    <div className={'column'}>
                      <Button
                        onClick={() => {
                          setShowCategorization({
                            show: true,
                            filter: false,
                            isNullify: true,
                          })
                        }}>
                        Añadir categorias
                      </Button>
                      <Row>
                        {modalNumber?.categories?.map((c, index) => (
                          <div key={index} className={'user-tag product-t ml-2'}>
                            <label>{c.name}</label>
                            <Icon
                              className={'delete-user-tag'}
                              icon={faTimes}
                              tooltip={'Quitar'}
                              color={'white'}
                              onClick={() => {
                                modalNumber.categories = modalNumber.categories.filter(
                                  d => c.id !== d.id,
                                )
                                setModalNumber({ ...modalNumber })
                              }}
                            />
                          </div>
                        ))}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingPaymentReceipt}
              color={'secondary'}
              onClick={() => setModalNumber({ show: false, id: null })}>
              Cerrar
            </Button>
            <Button
              loading={loadingUpdateParams || loadingNullifyNumber}
              disabled={loadingPaymentReceipt || paymentReceipt || !modalNumber.number}
              variant={'info'}
              icon={faCheckDouble}
              right
              onClick={() => {
                const object = { number: modalNumber.number }
                if (modalNumber.isNullify) {
                  let categories = ''
                  modalNumber.categories.forEach((c, index) => {
                    const sig = modalNumber.categories.length === index + 1 ? '' : ','
                    categories += c.id + sig
                  })
                  dispatch(
                    onNullifySpecificPaymentNumber(object.number, {
                      categories,
                      date: modalNumber.date ? modalNumber.date.valueOf() : null,
                    }),
                  )
                } else dispatch(onUpdateOrderParameters(object, modalNumber.id))
              }}>
              {modalNumber.isNullify ? 'Anular' : 'Confirmar'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Folder
        noMessage
        list={selectedCategories
          .map(p => p.id)
          .concat(modalNumber?.categories ? modalNumber.categories.map(p => p.id) : [])}
        onHide={() => setShowCategorization({ show: false, filter: false, order: null })}
        onAssign={item => {
          if (showCategorization.isNullify) {
            const d = modalNumber.categories.find(d => d.id === item.id)
            if (d) {
              modalNumber.categories = modalNumber.categories.filter(c => c.id !== d.id)
            } else {
              modalNumber.categories.push(item)
            }
            setModalNumber({ ...modalNumber })
          } else if (showCategorization.filter) {
            const d = selectedCategories.find(d => d.id === item.id)
            if (!d) {
              selectedCategories.push(item)
              setSelectedCategories([...selectedCategories])
            }
          } else if (showCategorization.order || ordersSelected.length > 0)
            assignCategorizationToOrder(item.id)
        }}
        data1={categorization?.children ? categorization.children[0] : {}}
        data2={categorization?.children ? categorization.children[1] : {}}
        show={showCategorization.show}
        type={showCategorization.type}
      />

      <Modal
        show={modalArchived.show}
        centered
        size={'lg'}
        onHide={() => setArchived({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Órdenes de Pago Archivadas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'column'}>
            <TableV2
              storageKey={`paymentArchi-${user.id}`}
              items={archivedPayments.sort((a, b) => {
                return b.createdAt - a.createdAt
              })}
              headers={archivedHeaders}
              onCheck={value => {
                setAllA(value)
                setPaymentSelected([])
              }}
              getItems={selected => {
                if (selected !== null) setPaymentSelected([...selected.map(x => x.id)])
                else setPaymentSelected([])
              }}
              loading={loadingArchivedPayments}
              isSelected={item => {
                const select = paymentSelected.filter(s => s === item.id).length !== 0
                return select && allA ? false : allA || select
              }}
              onClickTr={(e, item) => onPaymentRowClicked(item)}
              mobileAuto={true}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingArchivedPayments || loadingEditArchive}
              disabled={paymentSelected.length === 0 && !allA}
              color={'primary'}
              icon={faCheckDouble}
              right
              onClick={() => {
                editOrders(1, null)
              }}>
              Recuperar
            </Button>
            <Button
              loading={loadingArchivedPayments || loadingEditArchive}
              disabled={paymentSelected.length === 0 && !allA}
              color={'secondary'}
              icon={faTrash}
              onClick={() => {
                setAlert({
                  show: true,
                  type: 'info',
                  title: `¿Desea eliminar las órdenes de pagos seleccionadas?`,
                  text: `Esta acción no podrá ser revertida`,
                  showCancelButton: true,
                  onCancel: () => setAlert({ ...alert, show: false }),
                  onConfirm: () => {
                    editOrders(2, null)
                  },
                })
              }}>
              Eliminar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <ModalNullifyOrder
        order={showNullify.item}
        show={showNullify.show}
        onHide={() => setShowNullify({ show: false, item: null })}
        nullifyOrder={request => nullifyOrder(request)}
      />

      <Alert {...alert} />

      <FAB
        show={true}
        mainButtonStyles={
          loadingNullifyNumber || loadingPaymentReceipt || loadingSegmentationReport
            ? { backgroundColor: 'rgb(179, 87, 150, 0.9)' }
            : {}
        }
        loading={
          loadingNullifyNumber ||
          loadingPaymentReceipt ||
          loadingSegmentationReport ||
          loadingReportCSV
        }
        items={[
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Anular recibo físico',
            icon: faTrash,
            show: canNullifyPhysical,
            action: () => {
              setModalNumber({ show: true, id: null, isNullify: true, categories: [] })
            },
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Descargar reporte segmentado por clientes en Excel',
            icon: faFileExcel,
            show: true,
            action: () => downloadReportSegmented(true),
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Descargar reporte en Excel',
            icon: faFileExcel,
            show: true,
            action: () => downloadReportSegmented(),
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Ver órdenes archivadas',
            icon: faArchive,
            action: () => {
              setArchived({ show: true })
              dispatch(getArchivedPayment())
            },
            show: true,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Añadir categorización',
            icon: faTag,
            action: () => {
              setShowCategorization({ show: true, filter: false })
              dispatch(getAllCategorizations(17))
            },
            show: ordersSelected.length > 0 && !all,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Archivar órdenes',
            next: true,
            icon: faArchive,
            action: () => {
              const request = {
                all,
                orderIds: ordersSelected.map(s => s),
              }
              const params = {
                client: selected.client || null,
                start: filters.start,
                end: filters.end,
                categories: filters.categories,
                state: filters.state?.value,
              }
              setAlert({
                show: true,
                type: 'info',
                title: `¿Desea archivar las órdenes de pagos seleccionados?`,
                text: `Únicamente se archivaran los pagos con estado ”Ingresada, Anuladas y Diferidas”.
                            \n Los pagos archivados podrán visualizarse desde la papelera de pagos archivados`,
                showCancelButton: true,
                onCancel: () => setAlert({ ...alert, show: false }),
                onConfirm: () => {
                  dispatch(editMassivePayments(request, params))
                },
              })
            },
            show: ordersSelected.length > 0 || all,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Abonar a las órdenes seleccionadas',
            icon: faMoneyBill,
            action: () => {
              let balance = 0
              countsSelected.map(s => (balance += s.balance))
              setPayOrderModal({
                ...payOrderModal,
                show: true,
                balance,
                orders: countsSelected,
                id: countsSelected[0]?.client?.id,
                client: countsSelected[0]?.client,
              })
            },
            show: countsSelected.length > 0 && canPayCxc,
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Descargar reporte CSV',
            icon: faFileCsv,
            show: false,
            next: true,
            action: () => {
              const { start, end, categories, clientId } = filters
              dispatch(
                getCpcReportCSV({
                  start,
                  end,
                  client: clientId,
                  payment: 2,
                  type: 1,
                  categories,
                }),
              )
            },
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Realizar abonos con CSV',
            icon: faFileCsv,
            show: false,
            next: true,
            action: () => setPaymentCSVShow(true),
          },
          {
            style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
            text: 'Recibir pago adelantado',
            icon: faMoneyCheckAlt,
            show: true,
            next: true,
            action: () => setClientModal({ show: true, notCxc: true }),
          },
        ]}
        icon={faEllipsisV}
      />

      <PaymentsCSV
        show={paymentsCSVShow}
        onHide={update => {
          setPaymentCSVShow(false)
          if (update) setUp()
        }}
      />

      <PaymentTicket order={paymentTicket} company={paymentTicket.companyData} />
    </div>
  )
}
export default Payments
