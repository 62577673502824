import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import {
  Button,
  FormText,
  CustomDate,
  Checkbox,
  NumberField,
  SwitchV2,
  Select,
} from 'src/components'
import RadioGroup from 'src/components/inputs/RadioGroup/RadioGroup'
import { faCheckDouble, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { isAllowed } from 'src/selectors/modules.selector'
import { promotionPermissions } from 'src/enums/permissions'
import PromotionFormItems from 'src/content/Promotion/Form/PromotionFormItems'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import SimpleList from 'src/components/List/SimpleList'

interface IPromotionFormProps {
  promotion?: IPromotionRequest
  onHide: () => void
  onAction: (request: IPromotionRequest) => void
  loading: boolean
}

const daysOfWeekData = [
  { label: 'Lunes', value: '1' },
  { label: 'Martes', value: '2' },
  { label: 'Miércoles', value: '3' },
  { label: 'Jueves', value: '4' },
  { label: 'Viernes', value: '5' },
  { label: 'Sábado', value: '6' },
  { label: 'Domingo', value: '7' },
]

const addNewStringItem = (listString: string, newString: string): string => {
  const list: string[] = haveAnyValue(listString) ? listString.split(',') : []
  if (haveAnyValue(newString)) list.push(newString)

  return list.join(',')
}

const addNewStringWithoutItem = (listString: string, filterString: string): string => {
  let list: string[] = haveAnyValue(listString) ? listString.split(',') : []
  if (haveAnyValue(filterString)) list = list.filter(i => i !== filterString)

  return list.join(',')
}

const PromotionForm = ({ promotion, onHide, onAction, loading }: IPromotionFormProps) => {
  const show: boolean = haveAnyValue(promotion)
  const isCreate = !promotion?.id

  const pos: IPOS[] = useSelector(selectAllPOSUser)

  const canUpdate = useSelector(state => isAllowed(state, [promotionPermissions.update]))

  const disabledForm = (!isCreate ? !canUpdate : false) || loading

  const [data, setData] = useState<IPromotionRequest>({ items: [] })
  const [errors, setErrors] = useState<IPromotionRequestError>({})

  useEffect(() => {
    if (!show) return
    if (haveAnyValue(promotion.id)) setData(promotion)
    else setData({ items: [], dateType: 1, visible: true })
  }, [show])

  const onClose = () => {
    if (loading) return
    onHide()
    setData({ items: [] })
  }

  const onValidate = () => {
    const errors: IPromotionRequestError = {}
    if (!data?.name) errors.name = 'El nombre es requerido'
    if (data.dateType === 1) {
      if (!data?.start) errors.start = 'La fecha de inicio es requerida'
      if (!data?.end) errors.end = 'La fecha de finalización es requerida'
    } else if (!haveAnyValue(data.daysOfWeek))
      errors.daysOfWeek = 'Debes seleccionar al menos un día de la semana'

    if (!data?.startHour) errors.startHour = 'La hora de inicio es requerida'
    if (!data?.endHour) errors.endHour = 'La hora de finalización es requerida'
    if (!haveAnyValue(data.items) || data.items.length === 0)
      errors.items = 'Debes agregar al menos un ítem'
    if (!haveAnyValue(data.posIds) || data.posIds.split(',').length === 0)
      errors.posIds = 'Debes seleccionar al menos un punto de venta'

    setErrors(errors)
    if (Object.keys(errors).length > 0) return
    onAction(data)
  }

  const checks: IItemToGroup[] = [
    {
      type: 'radio',
      label: 'Rango de fechas',
      info: 'Selecciona el período de tiempo que deseas, indicando una fecha de inicio y una fecha de fin',
      id: 'range',
      checked: data?.dateType === 1,
      defaultValue: 1,
    },
    {
      type: 'radio',
      label: 'Días de la semana',
      info: 'Selecciona los días de la semana que aplican para esta acción o evento.',
      id: 'daysOfWeek',
      checked: data?.dateType !== 1,
      defaultValue: 2,
    },
  ]

  const onChangeCheck = (response: IGroupResponse) => {
    const check = checks.find(item => {
      if (response.value) return item.id === response.id
      else return item.id !== response.id
    })
    setData({ ...data, dateType: check.defaultValue })
  }

  const getDay = (date: number): Date => {
    if (!haveAnyValue(date)) return null
    return new Date(date)
  }

  const rangeCalendar = (
    <Row>
      <Col xl={6} md={6} sm={12} xs={12}>
        <CustomDate
          label={'Fecha de inicio'}
          value={getDay(data?.start)}
          required
          disabled={disabledForm}
          disabledDays={{}}
          onDayChange={(date: Date) => setData({ ...data, start: date?.valueOf() })}
          error={errors?.start}
        />
      </Col>
      <Col xl={6} md={6} sm={12} xs={12}>
        <CustomDate
          label={'Fecha de finalización'}
          value={getDay(data?.end)}
          required
          disabled={disabledForm}
          disabledDays={{}}
          onDayChange={(date: Date) => setData({ ...data, end: date?.valueOf() })}
          error={errors?.end}
        />
      </Col>
    </Row>
  )

  const haveDayOfWeek = (day: string): boolean => {
    if (!haveAnyValue(data?.daysOfWeek)) return false
    return data.daysOfWeek.includes(day)
  }

  const onChangeItem = (item: IPromotionItemRequest) => {
    const customItems = [...data.items]
    const index = customItems.findIndex(i => i.itemId === item.itemId)
    if (index > -1) customItems[index] = item
    else customItems.push(item)

    setData({ ...data, items: customItems })
  }

  const onDeleteItem = (item: IPromotionItemRequest) => {
    setData({ ...data, items: data.items.filter(i => i.itemId !== item.itemId) })
  }

  const daysOfWeek = (
    <Row>
      {daysOfWeekData.map(item => (
        <Col key={item.value} xl={2} lg={2} md={2} sm={6} xs={6}>
          <Checkbox
            label={item.label}
            disabled={disabledForm}
            checked={haveDayOfWeek(item.value)}
            changeValue={(checked: boolean) => {
              let daysSelected: string[] =
                data?.daysOfWeek?.split(',')?.filter(day => haveAnyValue(day)) || []
              if (checked) daysSelected.push(item.value)
              else daysSelected = daysSelected.filter(day => day !== item.value)

              setData({ ...data, daysOfWeek: daysSelected.join(',') })
            }}
          />
        </Col>
      ))}
      {haveAnyValue(errors.daysOfWeek) && (
        <Col xl={12} className={'color-red'}>
          * {errors.daysOfWeek}
        </Col>
      )}
    </Row>
  )

  const posSimple = (): ISimpleList[] => {
    const posSelected: ISimpleList[] = []
    if (haveAnyValue(data.posIds)) {
      const posIds = data.posIds.split(',')
      posIds.forEach(id => {
        const posFound = pos.find(p => p.id === parseInt(id))
        if (haveAnyValue(posFound))
          posSelected.push({
            id: posFound.id,
            title: posFound.name,
            subtitle: posFound.address,
          })
      })
    }
    return posSelected
  }

  const posSelection = (
    <Row>
      <Col xl={12}>
        <Select
          label={'Seleccionar punto de venta'}
          options={pos
            .map(p => ({ value: p.id, label: p.name }))
            .filter(p => !data?.posIds?.includes(p.value.toString()))}
          value={{ value: null, label: 'Selecciona un punto de venta' }}
          onChange={(posSelected: ISelect) => {
            setData({
              ...data,
              posIds: addNewStringItem(data.posIds, posSelected.value.toString()),
            })
          }}
          required
          error={errors?.posIds}
          disabled={disabledForm}
        />
      </Col>
      <Col xl={12}>
        <SimpleList
          disabled={disabledForm}
          items={posSimple()}
          onDelete={(item: ISimpleList) =>
            setData({
              ...data,
              posIds: addNewStringWithoutItem(data.posIds, item.id.toString()),
            })
          }
        />
      </Col>
    </Row>
  )

  return (
    <Modal show={show} centered size={'lg'} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{!isCreate ? promotion?.name : 'Nueva promoción'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {!isCreate && (
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <SwitchV2
                label={'Activo'}
                info={'Si está activo, la promoción será validada en el punto de venta.'}
                checked={data.active}
                onChange={value => setData({ ...data, active: value })}
                disabled={disabledForm}
              />
            </Col>
          )}
          <Col xl={6} lg={6} sm={12} xs={12}>
            <FormText
              label={'Nombre'}
              value={data?.name}
              changeValue={(value: string) => setData({ ...data, name: value })}
              required
              disabled={disabledForm}
              error={errors?.name}
            />
          </Col>
          <Col xl={6} lg={6} sm={12} xs={12}>
            <FormText
              label={'Descripción'}
              value={data?.description}
              changeValue={(value: string) => setData({ ...data, description: value })}
              disabled={disabledForm}
            />
          </Col>

          <Col xl={12} lg={12} sm={12} xs={12}>
            <RadioGroup
              label={'Seleccionar temporalidad'}
              items={checks}
              onChange={onChangeCheck}
              disabled={disabledForm}
            />
          </Col>
          <Col xl={12} lg={12} sm={12} xs={12} className={'mb-3'}>
            {data?.dateType === 1 ? rangeCalendar : daysOfWeek}
          </Col>

          <Col xl={6} lg={6} md={6} sm={12}>
            <NumberField
              label={'Hora de inicio'}
              value={data.startHour}
              onValueChange={(value: number) => setData({ ...data, startHour: value })}
              required
              disabled={disabledForm}
              min={0}
              max={23.59}
              decimals={2}
              error={errors?.startHour}
            />
          </Col>

          <Col xl={6} lg={6} md={6} sm={12}>
            <NumberField
              label={'Hora de finalización'}
              value={data.endHour}
              onValueChange={(value: number) => setData({ ...data, endHour: value })}
              required
              disabled={disabledForm}
              min={0}
              max={23.59}
              decimals={2}
              error={errors?.endHour}
            />
          </Col>

          <Col xl={12} lg={12} md={12} sm={12}>
            <SwitchV2
              label={'Visibilidad'}
              info={
                'Si está activo, los ítems de la promoción serán visibles en el punto de venta, de lo contrario, no se mostrarán.'
              }
              checked={data.visible}
              onChange={value => setData({ ...data, visible: value })}
              disabled={disabledForm}
            />
          </Col>

          <Col xl={12}>
            <PromotionFormItems
              items={data.items}
              onChange={onChangeItem}
              onDelete={onDeleteItem}
              error={errors.items}
              disabled={disabledForm}
            />
          </Col>

          <Col xl={12}>{posSelection}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            icon={faWindowClose}
            color={'secondary'}
            onClick={onClose}
            disabled={loading}>
            Cerrar
          </Button>
          <Button
            icon={faCheckDouble}
            onClick={onValidate}
            disabled={disabledForm}
            loading={loading}>
            {isCreate ? 'Crear' : 'Actualizar'}
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default PromotionForm
