enum CookieEnum {
  userToken = 'userToken',
  company = 'company',
}

const CookieExpirationHours = {
  [CookieEnum.userToken]: 1,
  [CookieEnum.company]: 24,
}

export { CookieEnum, CookieExpirationHours }
