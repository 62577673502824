const orderPaymentStatusEnum = {
  ENTERED: 1,
  CONFIRMED: 2,
  REJECTED: 12,
  NULLIFY: 13,
}

const orderStatsNames = {
  1: 'Ingresadas',
  2: 'Confirmadas',
  12: 'Rechazada',
  13: 'Anulada',
}

const getOrderPaymentStatus = (): ISelectNumber[] => {
  return Object.keys(orderPaymentStatusEnum).map(state => ({
    value: orderPaymentStatusEnum[state],
    label: orderStatsNames[orderPaymentStatusEnum[state]],
  }))
}

export { orderPaymentStatusEnum, getOrderPaymentStatus }
