import React from 'react'
import { Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { TaxFree } from 'src/components/Items/TaxFree/TaxFree'
import { itemTypes, toMoney } from 'src/utils/utilities'
import { LineQuantities } from '../LineQuantities'
import {
  selectCompanyFieldById,
  selectCompanyFieldEditPricePurchase,
  selectFelItemTypes,
} from 'src/selectors/company.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes } from 'src/actions/warehouse.actions'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { ItemPrice } from '../Row/ItemPrice'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { ItemDiscount } from '../Row/ItemDiscount'
import {
  Button,
  CustomDate,
  FormText,
  Icon,
  NumberField,
  Select,
  Switch,
} from 'src/components'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { setGeneralConfig, setItemTable } from 'src/actions/transaction.action'
import {
  selectConfigTransactionTable,
  selectItemSelected,
} from 'src/selectors/transaction.selector'
import { SerieItem } from 'src/components/Items/Series/SerieItem'
import { useLocation } from 'react-router-dom'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { formatNumberWithCommas } from 'src/utils/formatters'

interface Props {
  type: number
  isEdit: boolean // Edición de orden
  seeInputTax: boolean
  onChangePercentDiscount: (item: IItemSelectTable, value: ISelect) => void
  onChangeDiscount: (item: IItemSelectTable, discount: number) => void
  discounts: ISelect[]
  setUp: () => void
}

/**
 * Modal que muestra los detalles de un item seleccionado en la tabla de transacción
 * @component
 * @param {number} type Tipo de transacción
 * @param {boolean} isEdit Edición de orden
 * @param {function} onChangePercentDiscount Función para cambiar el descuento por porcentaje
 * @param {function} onChangeDiscount Función para cambiar el descuento por valor
 * @param {ISelect[]} discounts Lista de descuentos
 * @param {function} setUp Función para obtener los items a seleccionar
 * @param {boolean} seeInputTax Indica si se ve el impuesto de entrada
 * @returns
 */
export const ModalValueFill = ({
  type,
  isEdit,
  onChangeDiscount,
  onChangePercentDiscount,
  discounts,
  setUp,
  seeInputTax,
}: Props) => {
  const dispatch = useDispatch()

  const loadingLineItems = useSelector(state =>
    loadingSelector([actionTypes.GET_LINE_ITEMS_TO_SELECT])(state),
  )
  const generalConfigTable: IGeneralConfigTTable = useSelector(
    selectConfigTransactionTable,
  )
  const itemTable: IItemTable = useSelector(selectItemSelected)

  const { item } = itemTable

  const fieldEditPrice = useSelector(selectCompanyFieldEditPricePurchase)
  const maxDiscountCompany = useSelector(state => selectCompanyFieldById(state, 89))
  const disabledDiscounts = useSelector(state => selectCompanyFieldById(state, 84))
  const warehouseLocations = useSelector(state => selectCompanyFieldById(state, 123))
  const currentCurrency = useSelector(selectCurrentCurrency)
  const felItemTypes = useSelector(selectFelItemTypes)

  const itemOnlyCost = fieldEditPrice || item.decimals
  const isPurchaseExpense = type === itemTypes.PURCHASE || type === itemTypes.EXPENSE
  const useLocations = warehouseLocations && warehouseLocations.value !== '0'
  const importation = useLocation().pathname.includes('/importaciones')

  const getSimpleListVariation = (
    variations: ICategorization[],
    name: string,
    hide = true,
    isLocation?: boolean,
  ) => {
    return (
      <>
        {/* @ts-expect-error Error por JS*/}
        <ListVariations
          selected={variations}
          groupName={name}
          hideMessage={hide}
          isLocations={isLocation}
          border
        />
      </>
    )
  }

  const updateValues = () => {
    dispatch(setItemTable({ item: { ...item, edited: isEdit } }))
  }

  const getSubTotal = (): number => {
    let sub = 0
    item.haveQuantities = false
    item.line.forEach(x => {
      if (x.quantity > 0 && x.ownPrice) {
        item.haveQuantities = true
        sub = sub + x.quantity * (x.ownPrice || 0)
      }
    })
    return Number(sub.toFixed(2))
  }

  const totalAmount: number = (item.subTotal || 0) + (seeInputTax ? item.tax || 0 : 0)

  return (
    <Modal
      show={generalConfigTable.showDetails}
      centered
      size={'xl'}
      style={{
        border: '1px solid',
      }}
      onHide={() => {
        dispatch(
          setGeneralConfig({
            showDetails: false,
            showModalItems: !item.key,
          }),
        )
        dispatch(setItemTable({ item: {} }))
      }}>
      <Modal.Header closeButton>
        <Modal.Title>{itemTable?.item?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {type !== itemTypes.WASTE && type !== itemTypes.TRANSFER && (
            <Col xl={12} md={12} className={'space-between'}>
              <div className={'pi-body-title  left'}>Subtotal</div>
              <span> {toMoney(totalAmount)}</span>
            </Col>
          )}
          {type === itemTypes.SELL && (
            <Col>
              <TaxFree
                item={item}
                toSelect={item}
                updateTax={value => {
                  dispatch(
                    setItemTable({
                      item: {
                        ...item,
                        includeIva: value.includeIva,
                        phraseReference: value.phraseReference,
                        edited: isEdit,
                      },
                    }),
                  )
                }}
              />
            </Col>
          )}
          <Col xl={12} md={12}>
            <Row>
              <Col xs={{ span: 10, offset: 1 }}>
                <div className="space-between mt-3">
                  <div>
                    {item.listLocations &&
                      useLocations &&
                      getSimpleListVariation(
                        item.listLocations,
                        'Ubicación',
                        false,
                        true,
                      )}
                    {getSimpleListVariation(
                      item.listCategorization,
                      'Variación',
                      true,
                      false,
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          {loadingLineItems ? (
            <Col xs={12}>
              <div className={'pb-custom'}>
                <ProgressBar
                  label="Cargando"
                  animated
                  now={100}
                  style={{ marginBottom: 10 }}
                />
              </div>
            </Col>
          ) : (
            <Col
              xs={item.individualTotal || itemOnlyCost ? 6 : 12}
              style={{ marginTop: 10 }}>
              <LineQuantities
                currentItem={item}
                mobile
                maxDiscountCompany={Number(maxDiscountCompany.value)}
                updateValues={() => {
                  updateValues()
                }}
                updateTax={() => {
                  updateValues()
                }}
                fieldEditPrice={fieldEditPrice}
                useLocations={false}
                type={type}
              />
            </Col>
          )}
          {type === itemTypes.SELL && (
            <>
              <Col style={{ marginTop: 5 }}>
                <ItemPrice
                  item={item}
                  currentCurrency={currentCurrency}
                  onUpdate={() => {
                    updateValues()
                  }}
                  mobile
                />
              </Col>

              {disabledDiscounts?.value === 'false' && (
                <>
                  {!generalConfigTable.haveDiscountGeneral ? (
                    <Col xl={12} md={12} xs={12}>
                      <ItemDiscount
                        mobile
                        disabled={generalConfigTable.haveDiscountGeneral}
                        discounts={discounts}
                        item={item}
                        onChangeDiscount={onChangeDiscount}
                        onChangePercentDiscount={onChangePercentDiscount}
                      />
                    </Col>
                  ) : (
                    <>
                      <label>Hay un descuento General activo</label>
                    </>
                  )}
                </>
              )}
              {item.id === 1 && (
                <Col xl={12}>
                  <Select
                    label={'Bien o Servicio'}
                    info={
                      'La propiedad "Bien o Servicio" se usará para la factura electrónica'
                    }
                    required
                    value={felItemTypes.find(({ value }) => value === item.felItemTypeId)}
                    options={felItemTypes}
                    onChange={felItemType => {
                      item.felItemTypeId = felItemType.value
                      updateValues()
                    }}
                  />
                </Col>
              )}
              <Col xl={12} md={12}>
                <FormText
                  label={'Comentario'}
                  value={item.commentary}
                  prepend={<Icon icon={faComment} />}
                  onChange={({ target }) => {
                    item.commentary = target.value
                    updateValues()
                  }}
                  as={'textarea'}
                  rows={5}
                  max={1000}
                />
              </Col>
            </>
          )}
          {isPurchaseExpense && (
            <>
              <Col
                xs={itemOnlyCost || item?.line?.length > 1 ? 6 : 12}
                style={{ marginTop: 10 }}>
                {item.line &&
                  item?.line
                    .filter(x =>
                      item.individualTotal || itemOnlyCost
                        ? true
                        : x.productId === item.id,
                    )
                    .map((line, index) => (
                      <NumberField
                        key={index}
                        isMoney
                        appendBefore
                        label={itemOnlyCost ? 'Costo unitario' : 'Sub total'}
                        containerStyle={{
                          marginBottom: item.individualTotal ? 40 : 0,
                        }}
                        disabled={line.quantity === 0 || !line.quantity}
                        value={
                          itemOnlyCost
                            ? line.ownPrice
                            : (item.individualTotal ? line.subTotal : item.subTotal) || 0
                        }
                        min={0}
                        onValueChange={value => {
                          if (itemOnlyCost) {
                            line.ownPrice = value
                            item.subTotal = getSubTotal()
                          } else if (item.individualTotal) {
                            line.subTotal = value
                            if (line.quantity > 0) {
                              const price = value / line.quantity
                              line.ownPrice = Number(price.toFixed(2))
                            }
                          } else {
                            line.subTotal = value
                            item.subTotal = value
                          }

                          if (generalConfigTable.withOutIVA)
                            line.purchasePrice =
                              Number(line.ownPrice) -
                              Number(line.ownPrice) *
                                ((generalConfigTable.withOutIVA.value || 0) / 100)
                          updateValues()
                        }}
                        decimals={4}
                      />
                    ))}
              </Col>
              {!itemOnlyCost && item?.line?.length > 1 && (
                <Col>
                  <Switch
                    checked={item.individualTotal}
                    onChange={({ target }) => {
                      const { checked } = target
                      item.individualTotal = checked
                      if (checked) {
                        item.subTotal = 0
                        if (importation) {
                          item.tax = 0
                          item.customPrice = 0
                        }
                      } else
                        item.line.forEach(itemLine => {
                          itemLine.ownPrice = 0
                          itemLine.subTotal = 0
                          if (importation) {
                            itemLine.tax = 0
                            itemLine.customPrice = 0
                          }
                        })
                      updateValues()
                    }}
                    info={'Aplicar subtotales individuales a el árbol de ítems'}
                    label={'Valores monetarios individuales'}
                    topLabel
                  />
                </Col>
              )}

              {seeInputTax && (
                <Col xl={12}>
                  <Row>
                    <Col xl={12} className={'mt-2'}>
                      <NumberField
                        label={'Impuesto'}
                        disabled={!haveAnyValue(item.subTotal)}
                        style={{ width: '100%' }}
                        decimals={4}
                        value={item.tax}
                        min={0}
                        onValueChange={value => {
                          if (value > item.subTotal) value = item.subTotal
                          item.tax = value
                          updateValues()
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              )}

              <Col xs={12}>
                <CustomDate
                  label="Fecha de vencimiento"
                  disabled={!item.value}
                  value={item.expirationDate}
                  disabledDays={[]}
                  onDayChange={date => {
                    item.expirationDate = date
                    updateValues()
                  }}
                />
              </Col>
            </>
          )}
          {type === itemTypes.PURCHASE && !importation && item.decimals === null && (
            <Col xs={12}>
              {/* @ts-expect-error JS */}
              <SerieItem
                toSelect={item}
                changeItem={() => updateValues()}
                type={type}
                importation={importation}
              />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button
            disabled={!item.haveQuantities} // && !modalItem.outs}
            color={'accent'}
            onClick={() => {
              if (itemTable.index === null)
                dispatch(setGeneralConfig({ showModalItems: true }))
              dispatch(setItemTable({ saveItemModal: true }))
              setUp()
            }}>
            Guardar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
